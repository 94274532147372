import { LitElement, css, html } from 'lit';
import '../styles/spacing-variables.css';

export class ToggleButton extends LitElement {
    static get properties() {
        return {
            isClicked: { type: Boolean },
            isNavbar: { type: Boolean },
            buttonHeight: { type: String },
            buttonWidth: { type: String }, 
            route: { type: String }
        }
    }

   static styles = css`
        .button-container {
            display: flex;
            border: none;
            background-color: transparent;
            font-size: 48px;
            align-items: baseline;
            color: #000;
            cursor: pointer;
            margin-right: 8px;
        }

        .button-toggle {
            font-size: 40px;
        }

        ::slotted(*) {
            font-size: 40px;
            margin-left: 16px;
            text-align: left;
        }

                /* Extra small devices (phones, 600px and down) */
        @media only screen and (max-width: 600px) {
            .button-container {
                display: flex;
                align-items: center;
            }

            .button-toggle {
                font-size: 32px;
                margin-right: 0;
            }

            ::slotted(*) {
                font-size: 24px;
                margin-left: 8px;
                text-align: left;
            }
        }
   `

    constructor() {
        super();
        this.isClicked = false;
        this.isNavbar = false;
        this.buttonHeight = '';
        this.buttonWidth = '';
        this.route = '';
    }
    
    render() {
        return html`
            <button class="button-container" @click=${this.toggle}>
                <p class="button-toggle">
                    +
                </p>
                <slot></slot>
            </button>
        `
    }

    firstUpdated(e) {
        const button = this.shadowRoot.querySelector('.button-toggle');
        this.isNavbar === true ? button.style.transform = 'rotateZ(45deg)' : button.style.transform = null
        this.isClicked ? button.style.transform = 'rotateZ(45deg)': button.style.transform = null

        if (!this.isClicked) {
            const event = new CustomEvent('checked', {
                detail: { value: this.isClicked},
                bubbles: true,
            });
            this.dispatchEvent(event);
            if (e.defaultPrevented) {
                e.preventDefault();
            }
        }
    }
    
    toggle(e) {
        this.isClicked = !this.isClicked;
        const button = this.shadowRoot.querySelector('.button-toggle')
        
        if (this.isClicked) {
            button.style.transform = 'rotateZ(45deg)'
        } else {
            button.style.transform = null;
        }
        const event = new CustomEvent('checked', {
            detail: { value: this.isClicked},
            bubbles: true,
          });
          this.dispatchEvent(event);
          if (e.defaultPrevented) {
            e.preventDefault();
          }
    }
}

customElements.define('app-toggle-button', ToggleButton);