export const seasonings = [
    {
        title: 'Garlic Butter',
        ingredients: `
            <ul>
                <li> 1 Cup Butter room temperature </li>
                <li> 2 Tablespoons Olive Oil       </li>
                <li> ½ Cup Parmesan Cheese grated  </li>
                <li> ⅛ Cup Dried Parsley           </li>
                <li> 2 Tablespoons Garlic Powder   </li>
            </ul>
        `,
        instructions: `
            <ul>
                <li> Add the butter to the bowl of a stand mixer and whip using the whisk attachment until light and fluffy. </li>
                <li> Slowly add the olive oil while continuing to mix the butter on low.  Once it is completely incorporated, add the cheese, parsley and garlic powder and mix until well combined.</li>
                <li> Use immediately, or store in an airtight container in the fridge for up to 2 months. </li>
            </ul>
        `,
        originalLink: 'https://thecarefreekitchen.com/amazing-garlic-butter/#recipe'
    },
    {
        title: 'Taco Seasoning',
        ingredients: `
            <ul>
                <li> 1 tablespoon chili powder            </li>
                <li> 1 ½ teaspoons ground cumin           </li>
                <li> 1 teaspoon sea salt                  </li>
                <li> 1 teaspoon ground black pepper       </li>
                <li> ½ teaspoon ground paprika            </li>
                <li> ¼ teaspoon garlic powder             </li>
                <li> ¼ teaspoon onion powder              </li>
                <li> ¼ teaspoon crushed red pepper flakes </li>
                <li> ¼ teaspoon dried oregano </li>
            </ul>
        `,
        instructions: `
            <ul>
                <li>Mix ingredients</li>
                <li>Store in sealed container</li>
            </ul>
        `,
        originalLink: 'https://www.allrecipes.com/recipe/46653/taco-seasoning-i/'
    },
    {
        title: 'Pork Rib Rub',
        ingredients: `
            <ul>
                <li> 1 tablespoon cumin </li>
                <li> 1 tablespoon chili powder                                                                    </li>
                <li> 1 tablespoon ground garlic powder                                                            </li>
                <li> 1 tablespoon ground onion powder                                                             </li>
                <li> 1 tablespoon smoked paprika                                                                  </li>
                <li> 2 tablespoon coarse sea salt                                                                 </li>
                <li> 1 tablespoon coarse ground black pepper                                                      </li>
                <li> ½ teaspoon cayenne pepper                                                                    </li>
                <li> 2 teaspoon golden monk fruit or ½ teaspoon stevia (or to taste), using sweetener is optional!</li>
            </ul>
        `,
        instructions: `
            <ul>
                <li> Put all of the ingredients in a small bowl and mix until combined. </li>
                <li> Store in a 4 oz jar for up to 2-3 years, until it starts losing flavor. </li>
                <li> The recipe makes 7 Tablespoons of dry rub for pork, most recipes will call for 1-2 Tablespoons of dry rub mixture. You can half the recipe if preferred! </li>
            </ul>
        `,
        originalLink: 'https://wholelottayum.com/dry-rub-for-pork/#recipe'
    },
    {
        title: 'Rosemary Salt',
        ingredients: `
            <ul>
                <li> 1 cup (250g) Kosher salt       </li>
                <li> 14 sprigs (16g) fresh rosemary </li>
                <li> 8 sprigs (8g) fresh sage       </li>
                <li> 4 fresh garlic cloves          </li>
                <li> 1 lemon zested                 </li>
            </ul>
        `,
        instructions: `
            <ul>
                <li> Smash and peel the garlic. Strip the herbs by removing both the rosemary and sage leaves from the stem. Zest 1 lemon using a Microplane or the finest setting of a box grater.                  </li>
                <li> Combine all the ingredients along with the salt in a blender or a food processor and blend for 1 minute or until all ingredients have the texture of coarse sand and the color is lightly green.</li>
                <li> Store in an airtight Tupperware container in your refrigerator and try and use it up within two weeks for optimal freshness although it will last for 2 months.                                 </li>
            </ul>
        `,
        originalLink: 'https://www.youtube.com/watch?v=n3D4j26svoM'
    }
]