export const sauces = [
    {
        title: 'Brown Gravy',
        ingredients: `
            <ul>
                <li> 1 chicken stock cube / bouillon cube (sub 2 tsp powder) (Note 1) </li>
                <li> 1 beef stock cube / bouillon cube (sub 2 tsp powder) (Note 1)    </li>
                <li> 2 1/4 cups (565ml) boiling water                                 </li>
                <li> 60g/ 4 tbsp butter , unsalted                                    </li>
                <li> 4 tbsp flour , plain / all purpose                               </li>
                <li> 1/2 tsp onion powder (ok to omit or sub garlic powder)           </li>
                <li> 1/4 tsp finely ground black pepper                               </li>
                <li> Salt , if needed                                                 </li>
            </ul>
        `,
        instructions: `
            <ul>
                <li> Crumble cubes into boiling water, mix to dissolve. </li>
                <li> Melt butter in a saucepan over medium heat. </li>
                <li> Add flour, onion powder and pepper. Mix into butter with a wooden spoon or whisk. </li>
                <li> While stirring, slowly pour in half the liquid. Once incorporated into the flour butter roux (it will thicken quickly), add remaining liquid and mix. </li>
                <li> Stir for 1.5 – 2 minutes until it thickens to a gravy consistency (it's quick as water is already hot). Taste, add more salt and pepper if needed.    </li>
                <li> Serve over chops, steak, sausages, veggies, roast chicken, pork, beef, lamb.                                                                          </li>
            </ul>
        `,
        originalLink: 'https://www.recipetineats.com/gravy/#wprm-recipe-container-42544'
    },
    {
        title: 'Salsa',
        ingredients: `
            <ul>
                <li> 3 Roma Tomatos                  </li>
                <li> 1/2 Lime Squeezed               </li>
                <li> 2 Garlic Cloves                 </li>
                <li> 1 Tablespoon Fresh Cilantro     </li>
                <li> 1 White Onion (cut into chunks) </li>
                <li> 2 Jalepeno Peppers </li>
                <li> 3 Tablespoons Avacado Oil  </li>
            </ul>
        `,
        instructions: `
            <ul>
                <li> Warm the cast iron skillet to ~350F. </li>
                <li> Put avacado oil in the skillet. </li>
                <li> After oil is warmed. Place the all the ingredients into the skillet</li>
                <li> Cooked all the vegetables until charred and blistered.             </li>
                <li> Place cooked vegetables into blender                               </li>
                <li> Blend and put into container                                       </li>
            </ul>
        `,
        originalLink: 'https://competent-darwin-fcf33c.netlify.app/side-dishes'
    },
    {
        title: 'Avocado Creme',
        ingredients: `
            <ul>
                <li> 2 Large Avocados          </li>
                <li> 1 Cup Sour Cream          </li>
                <li> 2 Limes squeezed          </li>
                <li> 1 clove Garlic Bulb       </li>
                <li> 1/2 Cup Fresh Cilantro    </li>
                <li> Pinch Kosher Salt         </li>
                <li> Pinch Ground Black Pepper </li>
                <li> 1 Teaspoon Ground Cumin  </li>
            </ul>
        `,
        instructions: `
            <ul>
                <li>Blend all ingredients</li>
            </ul>
        `,
        originalLink: 'https://competent-darwin-fcf33c.netlify.app/side-dishes'
    },
    {
        title: 'Lime Creme',
        ingredients: `
            <ul>
                <li> 8 oz Sour Cream           </li>
                <li> 1 Lime (Squeezed)         </li>
                <li> 1/4 teaspoon Kosher Salt  </li>
                <li> 1 Lime(Zested)            </li>
                <li> 1 Garlic Clove (Minced)   </li>
            </ul>
        `,
        instructions: `
            <ul>
                <li>Blend all the ingredients</li>
            </ul>
        `,
        originalLink: 'https://competent-darwin-fcf33c.netlify.app/side-dishes'
    },
    {
        title: 'Queso Blanco',
        ingredients: `
            <ul>
                <li> 8 oz or 1/2 lb White American Cheese (whole milk) </li>
                <li> 1/2 cup Half and Half                             </li>
                <li> 1/8 cup Homemade Salsa                            </li>
            </ul>
        `,
        instructions: `
            <ul>
                <li> Heat all ingredients together in a sauce pan. </li>
                <li> Serve once all ingredients are in a creamy, liquid state. </li>
            </ul>
        `,
        originalLink: 'https://competent-darwin-fcf33c.netlify.app/side-dishes'
    },
    {
        title: 'Bacon Beer Cheese',
        ingredients: `
            <ul>
                <li> 6 - 8 pieces Bacon                    </li>
                <li> 4 - 5 Green Onions                    </li>
                <li> 8 oz Cream Cheese                     </li>
                <li> 8 oz Velveeta Cheese                  </li>
                <li> 2 Tablespoons Worcestershire sauce    </li>
                <li> 1 Tablespoon Montreal Steak Seasoning </li>
                <li> 6 oz Hoppy beer (like an IPA)         </li>
            </ul>
        `,
        instructions: `
                <ul>
                    <li> Cook bacon in a small pot until crisp, remove from pan and set aside on paper towels                                                                                      </li>
                    <li> Remove most of the grease, and add green onions until softened, then put back in bacon, cream cheese, Velveeta, Worcestershire, Montreal seasoning, green onions and beer </li>
                    <li> Cook on low until all is combined and melted - serve with something to dip                                                                                                </li>
                </ul>
        `,
        originalLink: 'https://www.thecookingguy.com/cookbook/bacon-beer-cheese-dip?rq=cheese%20dip'
    },
    {
        title: 'Pimento Cheese Dip',
        ingredients: `
            <ul>
                <li> 8ounces extra-sharp Cheddar, grated with a food processor or hand grater (not pre-grated)  </li>
                <li> ¼cup softened cream cheese (2 ounces), pulled into several pieces                          </li>
                <li> Scant ½ cup jarred pimento or other roasted red peppers (from a 7-ounce jar), finely diced </li>
                <li> 3tablespoons Duke’s, Hellmann’s or other high-quality store-bought mayonnaise              </li>
                <li> ½teaspoon red-pepper flakes                                                                </li>
                <li> Salt and black pepper to taste                                                             </li>
            </ul>
        `,
        instructions: `
            <ul>
               <li> In a large mixing bowl, place the Cheddar in an even layer. Scatter the cream cheese, pimentos, mayonnaise, red-pepper flakes and salt and pepper over the Cheddar. Using a spatula, mix the pimento cheese until it is smooth and spreadable. </li>
               <li> Transfer the pimento cheese to a bowl or container, cover tightly, and store in the refrigerator for up to 1 week. </li>
            </ul>
        `,
        originalLink: 'https://cooking.nytimes.com/recipes/1017354-pimento-cheese'
    },
    {
        title: 'Ranch',
        ingredients: `
            <ul>
                <li> 1/4 cup mayonnaise                                         </li>
                <li> 1/4 cup sour cream                                         </li>
                <li> 1/4 cup buttermilk                                         </li>
                <li> 3 tablespoons each chopped fresh parsley, dill & chives    </li>
                <li> 2 cloves garlic, finely minced                             </li>
                <li> 1/2 teaspoon onion powder                                  </li>
                <li> 1/4 teaspoon each Kosher salt & fresh ground black pepper  </li>
                <li> Juice of 1/2 lemon                                         </li>
            </ul>
        `,
        instructions: `
            <ul>
                <li> Combine all ingredients in a medium bowl and mix really well to combine. </li>
                <li> Refrigerate up to 4 days                                                 </li>
            </ul>
        `,
        originalLink: 'https://www.thecookingguy.com/recipes/homemade-ranch'
    },
    {
        title: 'Pizza Sauce',
        ingredients: `
            <ul>
            <h4>IF USING RETAIL TOMATOES (28 OZ CAN)</h4>
            <ul>
                <li> 1 28oz Can of Tomatoes                                               </li>
                <li> 3g Salt (volume can vary - check your salt container for conversions)</li>
                <li> 1 tsp Dried Oregano                                                  </li>
                <li> 1/2 tsp Dried Thyme                                                  </li>
                <li> 1/4 tsp Garlic Powder                                                </li>
                <li> 1/4 tsp Red Pepper Flakes                                            </li>
            </ul>
            <h4>IF USING COMMERCIAL TOMATOES (~102OZ CAN)</h4>
            <ul>
                <li> 1 102oz Can of Tomatoes                                                                    </li>
                <li> 11g Salt, plus more to taste (volume can vary - check your salt container for conversions) </li>
                <li> 3 1/2 tsp Dried Oregano                                                                    </li>
                <li> 1 3/4 tsp Dried Thyme                                                                      </li>
                <li> 1 tsp Garlic Powder                                                                        </li>
                <li> 1 tsp Red Pepper Flakes                                                                    </li>
            </ul>
        `,
        instructions: `
            <ul>
                <li> Combine all ingredients and blend thoroughly. Transfer to a heavy saucepan or pot (ideally stainless steel), and simmer until reduced by about 25-50% (depending on the initial thickness of your tomatoes. You’ll know it’s ready when there is very little moisture remaining in the sauce, and you can leave a streak in the pan when you run a spoon through it. </li>
                <li> The sauce can be stored in the refrigerator for up to 3 days before using. </li>
            </ul>
        `,
        originalLink: 'https://www.charlieandersoncooking.com/recipes/ultimate-detroit-style-pizza'
    },
    {
        title: 'Sawmill Gravy without Sausage',
        ingredients: `
            <ul>
                <li> ¼ cup unsalted butter                  </li>
                <li> ¼ cup all-purpose flour                </li>
                <li> 2 cups whole milk                      </li>
                <li> 1 teaspoon freshly cracked black pepper</li>
                <li> ½ teaspoon salt                        </li>
                <li> ¼ teaspoon rubbed sage                 </li>
                <li> ¼ teaspoon garlic powder               </li>
                <li> ¼ teaspoon onion powder                </li>
            </ul>
        `,
        instructions: `
            <ul>
                <li> Melt butter in a 4-quart pot or cast iron skillet over medium heat. Stir in flour until a roux paste forms. Cook, stirring constantly, until flour is a light golden color, 5 to 10 minutes.</li>
                <li> Add milk, whisking vigorously. Season with pepper, salt, sage, garlic powder, and onion powder.                                                                                             </li>
                <li> Cook until thickened but not boiling, about 5 minutes.                                                                                                                                      </li>
            </ul>
        `,
        originalLink: 'https://www.allrecipes.com/recipe/261135/sawmill-gravy-without-sausage/'
    },
    {
        title: 'Sausage Gravy',
        ingredients: `
            <ul>
                <li> 1 Pound Ground Pork Sausage            </li>
                <li> 2 Tablespoons Butter                   </li>
                <li> 1/3 Cup Flour                          </li>
                <li> 3 Cups Milk                            </li>
                <li> 1/4 teaspoon Garlic Powder             </li>
                <li> 1/4 teaspoon Seasoned Salt             </li>
                <li> 1/4 teaspoon Salt, More to taste       </li>
                <li> 1 teaspoon Black Pepper, More to taste </li>
            </ul>
        `,
        instructions: `
            <ul>
                <li> Brown the sausage in a large skillet over medium-high heat until no longer pink.          </li>
                <li> Add the butter to the pan and stir it around until melted.                                </li>
                <li> Sprinkle the flour over the sausage and stir for 2 minutes.                               </li>
                <li> Pour the milk into the sausage and flour mixture slowly, whisking constantly until smooth.</li>
                <li> Whisk in the seasonings. Taste the gravy and adjust seasoning if necessary.               </li>
                <li> Serve immediately with your favorite biscuits.                                            </li>
            </ul>
        `,
        originalLink: 'https://thesaltymarshmallow.com/best-sausage-gravy/'
    }
]