import { LitElement, css, html } from "lit";
import './app-toggle-button';
import '../styles/spacing-variables.css'
import '../styles/font-variables.css'

export class Navbar extends LitElement {
    static properties = {
            logoUrl: { type: String },
            isMenuOpened: { type: Boolean },
            route: { type: String }
    }

    static styles = css`
        nav {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px;
            height: 48px;
            width: 100%;
            align-content: center;
            border-bottom: solid black 2px;
        }

        a {
            color: #000;
        }
        
        .button-toggle {
            display: flex;
            align-items: center;
            height: 100%;
        }

        .nav-panel {
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding: var(--spacing-md);
            align-items: center;
            font-size: var(--font-size-lg);
            margin-top: var(--spacing-xxs);
        }

        ::slotted(*) {
            display: flex;
            flex-direction: column;
            padding: 16px;
        }
        
        ::slotted(*:nth-child(1)) {
            border-right: solid black 2px;
            margin-left: 8px;
        }
    `

    constructor() {
        super();
        this.logoUrl = '/';
        this.route = '';
        this.isMenuOpened = this.route === 'search' ? true : false;
    }

    render() {
        return html`
            <nav>
                <a href=${this.logoUrl}>LofiRecipes</a>
                <app-toggle-button 
                    @checked=${this.toggleMenu} 
                    .route=${this.route} 
                    .isClicked=${this.isMenuOpened} 
                    .isNavbar=${true} 
                    class="button-toggle">
                </app-toggle-button>
            </nav>
            <div class="nav-panel">
                <slot name="categories-1" class="nav-panel-items"></slot>
                <slot name="categories-2" class="nav-panel-items"></slot>
            </div>
        `
    }

    toggleMenu(e) {
        const navPanel = this.shadowRoot.querySelector('.nav-panel')
        const isClicked = e.detail.value;

        if (isClicked) {
            navPanel.style.display = 'flex'
        } else {
            navPanel.style.display = 'none'
        }
    }
}

customElements.define('app-navbar', Navbar);