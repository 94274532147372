import { LitElement, html, css } from "lit-element";
import { navigator } from "lit-element-router";
import '../styles/spacing-variables.css'
import '../styles/font-variables.css'

export class Link extends navigator(LitElement) {
  static get properties() {
    return {
      href: { type: String },
      isActive: { type: Boolean }
    };
  }
  static get styles() {
    return css`
      a {
        display: inline-block;
        margin: var(--spacing-xs);
        color: #000;
        font-weight: var(--font-weight-light);
        font-size: var(--font-size-xl);
        padding: 8px;
      }
      .active {
        color: #fff;
        background-color: #000;
      }
      
      /* Extra small devices (phones, 600px and down) */
      @media only screen and (max-width: 600px) {
           a {
            font-size: var(--font-size-lg);
           }
        }
    `;
  }
  constructor() {
    super();
    this.href = "";
    this.isActive = false;
  }
  render() {
    return html`
      <a href="${this.href}" @click="${this.linkClick}" class="${this.isActive ? 'active' : ''}">
        <slot></slot>
      </a>
    `;
  }
  linkClick(event) {
    event.preventDefault();
    this.navigate(this.href);
  }
}

customElements.define("app-link", Link);