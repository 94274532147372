export const sideDishes = [
    {
        title: 'Perfectly Seasoned Roasted Potatoes',
        ingredients: `
            <ul>
                <li> 6 large Yukon Gold Potatoes</li>
                <li> ¼ cup olive oil            </li>
                <li> ½ teaspoons paprika        </li>
                <li> ¼ teaspoon garlic powder   </li>
                <li> 1 teaspoon onion powder    </li>
                <li> 1 teaspoon cumin           </li>
                <li> ½ teaspoon chili powder you can use a bit less if you are worried about the spice level </li>
                <li> ¼ teaspoon pepper          </li>
                <li> 1 teaspoon salt            </li>
            </ul>
        `,
        instructions: `
                <ul>
                    <li> Preheat the oven to 450 degrees F. Line a rimmed baking sheet with parchment paper (this prevents sticking, but do keep an eye on it as this recipe is baked at a higher heat).</li>
                    <li> Peel and dice the potatoes and place inside a large bowl. Set aside.                                                                                                           </li>
                    <li> In a smaller bowl, combine the olive oil and spices and stir well.                                                                                                             </li>
                    <li> Pour the olive oil mixture over the potatoes and stir to coat.                                                                                                                 </li>
                    <li> Spread the potatoes on the baking sheet.                                                                                                                                       </li>
                    <li> Bake at 450 degrees F for 35-45 minutes, stirring every 10 minutes.                                                                                                            </li>
                    <li> Once the potatoes are tender inside and crispy outside, they are done. Season with more salt if desired.                                                                       </li>
                </ul>
        `,
        originalLink: 'https://theprettybee.com/perfectly-seasoned-roasted-potatoes/#recipe'
    },
    {
        title: 'Worcestershire Green Beans',
        ingredients: `
            <ul>
                <li> 2 tablespoon olive oil                  </li>
                <li> 1 small shallot                         </li>
                <li> 1 pound fresh green beans – washed and trimmed </li>
                <li> 1 cup low sodium chicken stock          </li>
                <li> 3 tablespoons Worcestershire sauce      </li>
                <li> 1/2 teaspoons kosher salt*              </li>
                <li> 1/2 teaspoons fresh ground black pepper </li>
            </ul>
        `,
        instructions: `
            <ul>
            <li> Mince shallot. Clean beans and trim ends as needed. Set out the remaining ingredients and gather the necessary tools.                                                     </li>
            <li> Heat a lidded skillet over medium heat. Add cooking oil and allow to heat for one minute. Add minced shallots, then cook for 1 – 2 minutes or until fragrant.             </li>
            <li> Add worcestershire sauce, low-sodium chicken stock, salt, and pepper. Stir to combine and bring to a boil. Add green beans, stir to coat beans, then cover and cook, stirring occasionally, until beans are just slightly more firm than desired, about 8 – 10 minutes. </li>
            <li> Uncover, turn up the heat and allow to cook for 2 – 4 minutes to reduce the sauce. The sauce should become syrupy, coating the green beans and making the shallots stick. Mince shallot. Clean beans and trim ends as needed. Set out the remaining ingredients and gather the necessary tools. </li>
            <li> Heat a lidded skillet over medium heat. Add cooking oil and allow to heat for one minute. Add minced shallots, then cook for 1 – 2 minutes or until fragrant.             </li>
            <li> Add worcestershire sauce, low-sodium chicken stock, salt, and pepper. Stir to combine and bring to a boil. Add green beans, stir to coat beans, then cover and cook, stirring occasionally, until beans are just slightly more firm than desired, about 8 – 10 minutes. </li>
            <li> Uncover, turn up the heat and allow to cook for 2 – 4 minutes to reduce the sauce. The sauce should become syrupy, coating the green beans and making the shallots stick. </li>
            </ul>
        `,
        originalLink: 'https://reneenicoleskitchen.com/worcestershire-green-beans/'
    },
    {
        title: 'Beer Cheddar Cheese Pretzel Dip (Cold Spread)',
        ingredients: `
            <ul>
                <li> 8 oz. cheddar cheese, shredded        </li>
                <li> 8 oz. cream cheese                    </li>
                <li> ⅓ cup light beer                      </li>
                <li> 1½ tablespoons Worcestershire sauce   </li>
                <li> ½ tablespoon Dijon mustard ((or ½ teaspoon dry mustard)) </li> 
                <li> ½ teaspoon garlic powder ((or 1 small clove fresh garlic)) </li>
                <li> 2-3 green onions (scallions), chopped </li>
            </ul>
        `,
        instructions: `
            <ul>
                <li> Add all ingredients to a blender and mix until fully incorporated.                      </li>
                <li> Garnish with more scallions and serve cold with pretzels, bread or veggies for dipping! </li>
            </ul>
        `,
        originalLink: 'https://alekasgettogether.com/beer-cheese-pretzel-dip/#recipe'
    },
    {
        title: 'Bacon Cheddar Ranch Cheeseball!',
        ingredients: `
            <ul>
                <li> 16 ounces cream cheese </li>
                <li> 1 cup cooked bacon chopped into small pieces about 5 slices </li>
                <li> 1 package of dry Ranch dressing mix                         </li>
                <li> 1 cup shredded sharp cheddar cheese                         </li>
                <li> 2 chopped green onions                                      </li>
                <li> 1 cup chopped pecans                                        </li>
            </ul>
        `,
        instructions: `
            <ul>
                <li> In mixing bowl combine cream cheese, bacon, cheddar cheese, green onions and ranch mix. </li>
                <li> Mix well and form into a ball.                                                          </li>
                <li> Arrange pecan pieces on a plate and roll cheese ball in pecans.                         </li>
                <li> Refrigerate for 1 hour before serving.                                                  </li>
            </ul>
        `,
        originalLink: 'https://myincrediblerecipes.com/bacon-cheddar-ranch-cheeseball/'
    },
    {
        title: 'Italian Pasta Salad',
        ingredients: `
            <ul>
                <li> 1 box (12 oz) tricolor rotini pasta </li>
                <li> 1 cup chopped cucumber (peeled and seeded, about 1 cucumber) </li>
                <li> 1 cup halved cherry or grape tomates                         </li>
                <li> 1 cup crumbled feta cheese                                   </li>
                <li> 1 can (2.25 oz) sliced black olives drained                  </li>
                <li> 1/4 cup finely diced red onion                               </li>
                <li> 1/2 - 1 cup Italian salad dressing                           </li>
            </ul>
        `,
        instructions: `
            <ul>
                <li> Cook pasta according to box instructions. Remember to salt your pasta water with 1-2 teaspoons of salt. When pasta is done, drain it and run cold water over it so the noodles can cool. </li>
                <li> In a large mixing bowl, combine the cooked and cooled pasta, cucumber, tomatoes, feta cheese, olives, and red onion. Pour Italian dressing over it and stir together.                    </li>
                <li> *Start with 1/2 cup Italian dressing and go from there. I put about 3/4 cup dressing on the salad and then reserve the rest to add to the salad before serving to moisten it back up.    </li>
                <li> Can be eaten right away or refrigerated for a few hours before serving.                                                                                                                  </li>
                <li> * I like it cold so I refrigerate for 2-4 hours before serving.                                                                                                                          </li>
            </ul>
        `,
        originalLink: 'https://togetherasfamily.com/easy-italian-pasta-salad/#wprm-recipe-container-8358'
    },
    {
        title: 'Dutch Oven No Knead Bread',
        ingredients: `
            <ul>
                <li> 1 1/2 cups warm water (about 100 degrees F)                                                                                                                                          </li>
                <li> 1 packet yeast (active dry, instant, or quick rise- 2.25 teaspoons)                                                                                                                  </li>
                <li> 1/2 tablespoon fine grain salt (preferably NOT iodized, see notes)                                                                                                                   </li>
                <li> 3 1/4 cups all-purpose flour or bread flour, more if needed to get correct consistency, plus more for dusting (I use King Arthur AP flour – other flours may yield different results)</li>
            </ul>
        `,
        instructions: `
            <ul>
                <li> In a large bowl (or container), mix together the warm water (1.5 cups), yeast (1 packet/2.25 teaspoons), and table salt (1/2 tablespoon), until yeast and salt are fully or almost dissolved (I like using a whisk for this).                                                                                                                                                                                                                                                                                                             </li>
                <li> Add the flour (3.25 cups) to the bowl all at once and stir together until a sticky dough forms. Don't worry about mixing it too much, just make sure everything is uniformly wet. It WILL be messy and sticky. You can scrape what's left on the spoon with a silicone spatula. (photo below provided for what the texture should look like – if it seems too wet, add 1-2 tablespoons more flour at a time until it is more like the photo. If it seems too dry, do the same thing with more water).                                     </li>
                <li> Letting no knead bread dough rise in a bowl.                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              </li>
                <li> Cover the bowl with a kitchen towel (or place the container to the lid on ajar). Leave it for 2-3 hours to rise in a somewhat warm place until it's doubled in volume, like photo in previous step (If it's cold out, I like to preheat my oven for a few minutes, then turn it off, then place the bowl in the oven, trapping the heat inside. If your house is warm just leave it out, or if it's warm outside you can place it there).                                                                                                 </li>
                <li> After the two-three hour rise time when the dough has about doubled, you have two options: bake, or store in the fridge. See notes for how to store.                                                                                                                                                                                                                                                                                                                                                                                      </li>
                <li> 30 minutes to an hour before you are ready to bake, preheat your oven to 450 degrees F. Place your Dutch oven in the oven, with the lid ON, while the oven is preheating. Once the oven reaches 450, keep it preheating for another 20 minutes, so the Dutch oven gets good and hot.                                                                                                                                                                                                                                                      </li>
                <li> Scrape the dough into the edge of a piece of parchment paper dusted with flour. Make it into as much of a "loaf" shape as you can by folding the edges up on top of it (a silicone spatula works well for this).                                                                                                                                                                                                                                                                                                                          </li>
                <li> How to make no knead bread without touching the dough with your hands                                                                                                                                                                                                                                                                                                                                                                                                                                                                     </li>
                <li> Then, use the edges of the parchment paper to flip the loaf over so the floured side is on top and the loaf is in the center of the parchment paper. Don't worry about it looking beautiful or smooth on top. My Dutch oven is an oval, so I made an oval shape. You can do a circle if yours is round.                                                                                                                                                                                                                                   </li>
                <li> Optional: use a very sharp knife or clean razor blade to cut a few slits across the top of the loaf about 1/2" deep, across the entire top. I did this in a cross shape for mine.                                                                                                                                                                                                                                                                                                                                                         </li>
                <li> Take out the preheated Dutch oven and take off the lid, carefully! Grab the parchment from the sides and place the loaf in your Dutch oven.                                                                                                                                                                                                                                                                                                                                                                                               </li>
                <li> Place the cover back on the Dutch oven and place in the preheated oven on the center rack. Bake for 35 minutes at 450 degrees F (40 minutes if dough was cold from the fridge). Remove the lid and bake for another 5-10 minutes, until a golden brown color forms- longer if necessary. Remove the loaf from the dutch oven (grabbing the sides of the parchment paper works well here) and place it on a wire rack or cutting board to cool for at least 10 minutes (preferably 30 minutes – an hour, if you have time) before slicing. </li>
            </ul>
        `,
        originalLink: 'https://www.bowlofdelicious.com/dutch-oven-bread/#recipe'
    },
    {
        title: 'Funeral Potatoes',
        ingredients: `
            <ul>
                <li> 1 (32-ounce) package frozen diced hash browns, (or shredded potatoes) </li>
                <li> 2 cups sour cream                                                     </li>
                <li> 1 (10.5-ounce) can cream of chicken soup                              </li>
                <li> ½ cup unsalted butter, melted                                         </li>
                <li> 2 cups shredded cheddar cheese                                        </li>
                <li> 1 teaspoon salt                                                       </li>
                <li> 1 teaspoon onion powder, optional                                     </li>
                <li> 2-3 cups corn flakes, crushed                                         </li>
                <li> ¼ cup unsalted butter, melted                                         </li>
            </ul>
        `,
        instructions: `
            <ul>
                <li> Preheat the oven to 350°F. </li>
                <li> Place 1 (32-ounce) package hash browns in a colander and let sit until fully thawed and drained. </li>
                <li> Meanwhile, in a large bowl, combine 2 cups sour cream, 1 (10.5-ounce) can cream of chicken soup, and ½ cup melted butter and mix well. </li>
                <li> Add 2 cups shredded cheese, 1 teaspoon salt, and 1 teaspoon onion powder and mix until well combined.                                  </li>
                <li> Add potatoes to cheese mixture and stir until combined. Place in a lightly greased 9-x-13-inch baking dish.                            </li>
                <li> Combine 2-3 cups cornflakes and the remaining ¼ cup melted butter in a large resealable plastic bag and shake until cereal is coated.  </li>
                <li> Sprinkle buttered cornflakes over potatoes.                                                                                            </li>
                <li> Bake for 42–46 minutes. Serve warm.                                                                                                    </li>
            </ul>
        `,
        originalLink: 'https://lilluna.com/funeral-potatoes/#wprm-recipe-container-95618'
    },
    {
        title: 'Cream Drop Biscuits',
        ingredients: `
            <ul>
                <li> 3 Cups Flour               </li>
                <li> 4 Teaspoons Sugar          </li>
                <li> 1 Tablespoon Baking Powder </li>
                <li> 1/4 Teaspoon Baking Soda   </li>
                <li> 1 1/4 teaspoon Salt        </li>
                <li> 2 Cups (Warmed) Cream      
                    <ul>
                        <li>Warm the cream in the microwave for 80 - 90 seconds. Cream should be at a 95 - 100F</li>
                    </ul>
                </li>
            </ul>
        `,
        instructions: `
            <ul>
                <li> Preheat oven to 450 Degrees.                                                                                                                        </li>
                <li> Mix all the items together in a mixing bowl.                                                                                                        </li>
                <li> Scoop biscuit batter out with 1/3 cup serving size                                                                                                  </li>
                <li> Place biscuits evenly spaced out on baking sheet                                                                                                    </li>
                <li> Place baking sheet into the upper middle rack for 10 - 12 minutes. Or until the tops and bottoms begin to brown. Lightly butter tops when finished. </li>
            </ul>
        `,
        originalLink: 'https://competent-darwin-fcf33c.netlify.app/side-dishes'
    },
    {
        title: 'Microwave Popcorn in a Paper Bag',
        ingredients: `
            <ul>
                <li> 1 large lunch paper bag      </li>
                <li> 2 tablespoons popcorn kernels</li>
                <li> 1/4 teaspoon oil             </li>
                <li> Salt                         </li>
            </ul>
        `,
        instructions: `
            <ul>
                <li> Place the popcorn into a large lunchbox paper bag.                                                                                                                                         </li>
                <li> Drizzle the oil on top. Fold the top into three, leaving enough space for the kernels to pop.                                                                                              </li>
                <li> Place the bag on its side into the microwave. Start the cooking time at 3 minutes. Pay attention to the sound of kernels popping. As soon as they stop, remove the bag from the microwave. </li>
                <li> My microwave is 950 watts and my popcorn was ready in 2 minutes. Carefully open the bag — it will have hot steam coming out. Add salt and shake well again.                                </li>
                <li> Pour into a bowl and serve.                                                                                                                                                                </li>
            </ul>
        `,
        originalLink: 'https://www.youtube.com/watch?v=Ws8QSSZGZx0&list=LL&index=48'
    },
    {
        title: 'Air Fryer Baba Ganoush',
        ingredients: `
            <ul>
                <li> 1 medium eggplant, halved lengthwise       </li>
                <li> ½ teaspoon kosher salt                     </li>
                <li> 5 ½ tablespoons olive oil, divided         </li>
                <li> 1 bulb garlic                              </li>
                <li> ¼ cup tahini (sesame seed paste)           </li>
                <li> 2 tablespoons lemon juice, or more to taste</li>
                <li> ¼ teaspoon ground cumin                    </li>
                <li> ⅛ teaspoon smoked paprika                  </li>
                <li> 2 tablespoons crumbled feta cheese         </li>
                <li> 1 tablespoon chopped fresh parsley         </li>
                <li> ½ teaspoon lemon zest                      </li>
            </ul>
        `,
        instructions: `
            <ul>
                <li> Sprinkle cut sides of eggplant with salt. Let stand for 20 to 30 minutes. Blot dry with paper towels.                                                                                                                                                                                                                          </li>
                <li> Preheat an air fryer to 400 degrees F (200 degrees C).                                                                                                                                                                                                                                                                         </li>
                <li> Brush cut sides of eggplant with 1 tablespoon olive oil. Cut off the top 1/4 inch from the garlic bulb, exposing the cloves. Brush cloves with 1/2 tablespoon olive oil and wrap bulb in aluminum foil. Place eggplant and garlic in the air fryer basket.                                                                     </li>
                <li> Cook in the preheated air fryer until eggplant and garlic are tender and eggplant is deep golden brown, 15 to 20 minutes. Remove and allow to cool, about 10 minutes.                                                                                                                                                          </li>
                <li> Scoop flesh from eggplant and place into the bowl of a food processor. Add tahini, lemon juice, 4 cloves of roasted garlic (reserve remaining roasted garlic for another use), remaining 4 tablespoons olive oil, cumin, and paprika; pulse to a moderately smooth consistency. Top with feta cheese, parsley, and lemon zest. </li>
            </ul>
        `,
        originalLink: 'https://www.allrecipes.com/recipe/275991/air-fryer-baba-ganoush/'
    }
]