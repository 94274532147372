export const dinnerRecipes = [
    {
        title: 'Beef and Broccoli',
        ingredients: `
                <h4>Beef and Broccoli<h4>
                <ul>
                   <li> 1 lb flank steak, very thinly sliced into bite-sized strips</li>
                   <li> 2 Tbsp olive oil , (or vegetable oil), divided             </li>
                   <li> 1 lb broccoli, (cut into 6 cups of florets)                </li>
                   <li> 2 tsp sesame seeds, optional garnish                       </li>
                </ul>
                <h4>Stir Fry Sauce<h4>
                <ul>
                    <li> 1 tsp fresh ginger, grated (loosely packed)</li>
                    <li> 2 tsp garlic, grated (from 3 cloves)       </li>
                    <li> 1/2 cup hot water                          </li>
                    <li> 6 Tbsp low sodium soy sauce, (or GF Tamari)</li>
                    <li> 3 Tbsp packed light brown sugar            </li>
                    <li> 1 1/2 Tbsp corn starch                     </li>
                    <li> 1/4 tsp black pepper                       </li>
                    <li> 2 Tbsp sesame oil                          </li>
                </ul>
        `,
        instructions: `
                <ul>
                   <li> <strong>Prep:</strong> Start cooking white rice first so it's ready when the stir fry is done. Cover and freeze steak 30 minutes for easier slicing.                                                             </li>
                   <li> Combine all stir fry sauce ingredients in a bowl, stir well to dissolve the sugar, and set aside.                                                                                               </li>
                   <li> Place a large skillet over medium heat and add 1 Tbsp oil.  Add broccoli florets and sauté 4-5 minutes, partially covered with lid, stirring or tossing several times until broccoli is bright green and crisp-tender then remove from pan. Tip: If you prefer softer broccoli, add 2 Tbsp water before covering with the lid and it will steam cook the broccoli. </li>
                   <li> Increase heat to high heat and add 1 Tbsp oil. Add beef in a single layer and sauté 2 minutes per side or just until cooked through. Quickly pull out a piece to test for doneness.             </li>
                   <li> Add the sauce, reduce heat to medium/low and simmer 3-4 minutes. It will thicken. Add broccoli and stir to combine. Stir in 1-2 Tbsp water to thin the sauce if desired. Serve over white rice. </li>
                </ul>
        `,
        originalLink: 'https://natashaskitchen.com/beef-and-broccoli/'
    },
    {
        title: 'Homemade Shepards Pie',
        ingredients: `
            <h4>Meat and Vegetables<h4>
            <ul>
                <li> 1 tablespoon oil             </li>
                <li> 1 pound ground beef          </li>
                <li> 1 clove garlic chopped       </li>
                <li> 2 onions finely chopped      </li>
                <li> 2 medium carrots finely diced</li>
                <li> 2 sticks celery finely diced </li>
            </ul>
            <h4>Gravy/ Finish Filling<h4>
            <ul>
                <li> ½ teaspoon salt                    </li>
                <li> ¼ teaspoon ground paprika          </li>
                <li> ground black pepper to taste       </li>
                <li> 2 tablespoons white flour          </li>
                <li> 2 tablespoons tomato paste         </li>
                <li> ⅓ cup red wine OR more broth if avoiding alcohol </li>
                <li> 1 cup beef broth                   </li>
                <li> 1 cup frozen peas                  </li>
                <li> 1 tablespoon finely chopped parsley</li>
                <li> ½ teaspoon finely chopped thyme    </li>
                <li> 1 teaspoon finely chopped rosemary </li>
            </ul>
            <h4>Topping</h4>
            <ul>
               <li> 4 cups mashed potatoes (click for my recipe and you’ll have some leftovers to sneak during cooking) </li>
               <li> ½ cup shredded cheddar cheese </li>
            </ul>
        `,
        instructions: `
                <ul>
                <li><strong>Brown meat and vegetables:</strong>
                Heat the oil over medium high heat in a large, deep skillet. Brown the ground meat, then add the chopped vegetables and sauté until softened.</li>
                <li><strong>Season and deglaze the pan:</strong>
                Sprinkle salt, paprika, pepper and flour over the pan. Cook for 2 minutes, stirring often. Stir in tomato paste and cook, stirring all the time, until it smells roasted (about 1 minute). Pour in the red wine (OR beef broth if you prefer!), scratching any browned bits off the bottom.</li>
                <li><strong>Finish filling and cool:</strong>
                Then stir in broth, herbs and peas, simmer 4-5 minutes and then let cool in a 7×11 inch baking dish.</li>
                <li><strong>Prep mash:</strong>
                In the meantime, make your favorite mashed potatoes. You need about 4 cups of mash to top off the casserole. About 1.5 pounds of peeled potatoes should yield enough.</li>
                <li><strong>Bake pie:</strong>
                Preheat the oven to 400°F (200°C). Spread the mashed potatoes over the cold filling. (Be careful not to mix up the two.) Criss-cross with a fork for a great look and crispy edges! Sprinkle with the cheese. Bake at 400°F for 20 minutes or until golden (I recommend baking on a lined baking sheet to catch any spills).</li>
                </ul>
        `,
        originalLink: 'https://www.savorynothings.com/shepherds-pie/#wprm-recipe-container-12497'
    },
    {
        title: 'Salisbury Steak',
        ingredients: `
                <h4>Beef Patties:</h4>
                <ul>
                   <li> 1 pound lean ground beef            </li>
                   <li> 1 large egg                         </li>
                   <li> 1 tablespoon Worcestershire sauce   </li>
                   <li> 1 teaspoon Dijon mustard            </li>
                   <li> 3 cloves garlic minced              </li>
                   <li> 1/3 cup seasoned breadcrumbs        </li>
                   <li> 1/2 teaspoon onion powder           </li>
                   <li> 1/2 teaspoon salt                   </li>
                   <li> Pepper to taste                     </li>
                   <li> 1 tablespoon olive oil (for frying) </li>
                </ul>
                <h4>Sauce:</h4>
                <ul>
                   <li> 1 medium onion sliced or chopped </li>
                   <li> 8 ounces cremini mushrooms sliced</li>
                   <li> 2 tablespoons butter             </li>
                   <li> 3 tablespoons flour              </li>
                   <li> 1 3/4 cups beef broth            </li>
                   <li> 1 teaspoon Worcestershire sauce  </li>
                   <li> 1/2 teaspoon garlic powder       </li>
                </ul>
        `,
        instructions: `
                <ul>
                   <li> Prep all ingredients prior to getting started.                                                                                                                                                                                                                                                                                                          </li>
                   <li> Add all the beef patty ingredients (except for the olive oil) to a fairly large prep bowl. Using your hands, mix it together and then form 4 patties (oval shape and aim for the same thickness of about 1/2-3/4" or so for each patty).                                                                                                                </li>
                   <li> Add the olive oil to a large skillet over medium-high heat. Allow the skillet to heat up for a few minutes.                                                                                                                                                                                                                                             </li>
                   <li> Fry the beef patties for 5-6 minutes/side or until they're nicely browned. Transfer to a plate and set aside. Leave the fat in the skillet.                                                                                                                                                                                                             </li>
                   <li> Add the onion and mushrooms to the skillet. Sauté, stirring occasionally, until the water has cooked out and they're nicely seared (about 7-9 min). Transfer to a plate (the same plate as the beef patties is fine).                                                                                                                                   </li>
                   <li> Turn the heat down to medium. Add the butter to the skillet, and once it's melted, sprinkle in the flour. Cook, stirring often, for 1-2 minutes so the flour taste is cooked out.                                                                                                                                                                       </li>
                   <li> Whisk in the beef broth slowly, ensuring the flour has dissolved into the sauce.                                                                                                                                                                                                                                                                        </li>
                   <li> Whisk the Worcestershire sauce and garlic powder into the sauce.                                                                                                                                                                                                                                                                                        </li>
                   <li> Add the onions, mushrooms, and beef patties (and any juices from the plate) back into the skillet. Cook for another 5-7 minutes or so until the beef patties are fully cooked through and the sauce has thickened up a bit. If the gravy gets too thick, add a splash of water or more beef broth. Serve immediately with extra salt & pepper if needed.</li>
                </ul>
        `,
        originalLink: 'https://www.saltandlavender.com/salisbury-steak/#wprm-recipe-container-37265'
    },
    {
        title: 'Italian Sausage Bow Tie Pasta',
        ingredients: `
            <ul>
                <li> 12 oz bow tie pasta                                                    </li>
                <li> 2 tablespoons olive oil                                                </li>
                <li> 1 pound mild Italian sausage casings removed                           </li>
                <li> ½ teaspoon red pepper flakes optional                                  </li>
                <li> ½ cup diced onion                                                      </li>
                <li> 4 cloves garlic minced                                                 </li>
                <li> 1 can Italian-style plum tomatoes, drained and coarsely chopped 28 oz. </li>
                <li> 1 ½ cups heavy cream </li>
                <li> 1 cup packed spinach leaves cut into thin strips </li>
                <li> Fresh parsley minced (optional) </li>
            </ul>
        `,
        instructions: `
            <ul>
                <li> Bring a large pot of water to a boil. Cook pasta according to package directions; drain.                                                                                                                       </li>
                <li> Meanwhile, in a large skillet add the olive oil then cook and crumble the sausage and red pepper flakes over medium-high heat until cooked through and browned. Add onion and garlic. Cook until onion is tender.</li>
                <li> Add the tomatoes and heavy cream. Simmer until the mixture thickens, about 10 minutes. Add the spinach and cook an additional 3 minutes. Add the cooked pasta to the sauce. Sprinkle with parsley, if desired. </li>
            </ul>
        `,
        originalLink: 'https://www.lovebakesgoodcakes.com/italian-sausage-with-bow-tie-pasta/?utm_sq=grt5g1vmu8#recipe'
    },
    {
        title: 'Homemade Sloppy Joes',
        ingredients: `
            <ul>
                <li> 1 Tbsp butter                              </li>
                <li> 1 tsp olive oil                            </li>
                <li> 1 lb. ground beef                          </li>
                <li> 1/3 green bell pepper, minced              </li>
                <li> 1/2 large yellow onion, minced             </li>
                <li> 3 cloves garlic, minced                    </li>
                <li> 1 Tbsp tomato paste                        </li>
                <li> 2/3 cup ketchup                            </li>
                <li> 1/3 cup water                              </li>
                <li> 1 Tbsp brown sugar (or less, if you prefer)</li>
                <li> 1 tsp yellow mustard                 </li>
                <li> 3/4 tsp chili powder                 </li>
                <li> 1/2 tsp Worcestershire sauce         </li>
                <li> 1/2 tsp kosher salt                  </li>
                <li> 1/4 tsp red pepper flakes (optional) </li>
                <li> 1/4 tsp black pepper </li> 
                <li> dash of hot sauce (optional) </li>
            </ul>
        `,
        instructions: `
            <ul>
                <li> Heat butter and oil in large skillet over MED/MED-HIGH heat.  Add beef and brown, breaking apart into crumbles as it cooks, about 5 minutes.  Transfer to colander to drain. </li>
                <li> Add onion and bell pepper to same skillet and cook 2-3 minutes, until soft.  Add garlic and cook 30 seconds or so, until fragrant.  Add beef back to the skillet and add tomato paste.  Stir well. </li> 
                <li> Add ketchup, water, brown sugar, mustard, chili powder, Worcestershire sauce, salt, red pepper flakes (if using), and black pepper.  Stir well to combine. </li>
                <li> Cook over MED heat for 10-15 minutes, until mixture has thickened to your liking.  Remove from heat and serve over toasted buns. </li>
            </ul>
        `,
        originalLink: 'https://www.thechunkychef.com/best-homemade-sloppy-joes/#wprm-recipe-container-14379'
    },
    {
        title: 'Homemade Lasagna',
        ingredients: `
            <ul>
                <li> 12 lasagna noodles uncooked </li> 
                <li> 4 cups mozzarella cheese shredded and divided </li>
                <li> ½ cup parmesan cheese shredded and divided </li>
            </ul>
                <h4>Tomato Sauce</h4>
                <ul>
                    <li> ½ pound lean ground beef        </li>
                    <li> ½ pound Italian sausage         </li>
                    <li> 1 onion diced                   </li>
                    <li> 2 cloves garlic minced          </li>
                    <li> 36 ounces pasta sauce *see note </li>
                    <li> 2 tablespoons tomato paste </li> 
                    <li> 1 teaspoon Italian seasoning </li>
                </ul>
                <h4>Cheese Mixture</h4>
                <ul>
                    <li> 2 cups ricotta cheese </li> 
                    <li> ¼ cup fresh parsley chopped </li>
                    <li> 1 egg beaten </li>
                </ul>
        `,
        instructions: `
            <ul>
                <li> Preheat the oven to 350°F. In a large pot of salted water, boil lasagna noodles until al dente according to package directions. Drain, rinse under cold water, and set aside.                                                            </li>
                <li> In a large skillet or dutch oven, brown beef, sausage, onion, and garlic over medium-high heat until no pink remains. Drain any fat.                                                                                                     </li>
                <li> Stir in the pasta sauce, tomato paste, Italian seasoning, ½ teaspoon of salt, and ¼ teaspoon of black pepper. Simmer uncovered over medium heat for 5 minutes or until thickened.                                                        </li>
                <li> In a separate bowl, combine 1 ½ cups mozzarella, ¼ cup parmesan cheese, ricotta, parsley, egg, and ¼ teaspoon salt.                                                                                                                      </li>
                <li> Spread 1 cup of the meat sauce in a 9×13 pan or casserole dish. Top it with 3 lasagna noodles. Layer with ⅓ of the ricotta cheese mixture and 1 cup of meat sauce. Repeat twice more. Finish with 3 noodles topped with remaining sauce. </li>
                <li> Cover with foil and bake for 45 minutes. </li>
                <li> Remove the foil and sprinkle with the remaining 2 ½ cups mozzarella cheese and ¼ cup parmesan cheese. Bake for an additional 15 minutes or until browned and bubbly. Broil for 2-3 minutes if desired. </li>
                <li> Rest for at least 15 minutes before cutting. </li>
            </ul>
        `,
        originalLink: 'https://www.spendwithpennies.com/easy-homemade-lasagna/#wprm-recipe-container-145181'
    },
    {
        title: 'One Pan Creamy Gnocchi with Sausage',
        ingredients: `
            <ul>
                <li>16 ounces Italian sausage see note</li>
                <li>1/2 small onion chopped finely    </li>
                <li>4 cloves garlic minced            </li>
                <li>1/3 cup dry white wine (e.g. sauvignon blanc)</li>
                <li>1 (14 ounce) can diced tomatoes with juices  </li>
                <li>1 cup heavy/whipping cream                   </li>
                <li>1 pound uncooked potato gnocchi              </li>
                <li>1/2 cup freshly grated parmesan cheese       </li>
                <li>Handful fresh basil torn                     </li>
                <li>Salt & pepper to taste                       </li>
            </ul>
        `,
        instructions: `
            <ul>
                <li> Sauté the sausage and onion in a skillet over medium-high heat for 7-10 minutes, or until lightly browned.                                                                             </li>
                <li> Add the garlic to the pan and sauté for about 30 seconds.                                                                                                                              </li>
                <li> Add the wine and let it cook for about a minute.                                                                                                                                       </li>
                <li> Stir in the diced tomatoes, cream, and gnocchi. Once the sauce starts to bubble again, cover the pan and reduce the heat to medium. Cook for 5 minutes.                                </li>
                <li> Uncover the pan, give it a good stir, and let it cook for another few minutes, or until the gnocchi has cooked through and is nice and soft and the sauce has thickened to your liking.</li>
                <li> Stir in the parmesan cheese, basil, and salt & pepper, to taste. Serve immediately. </li>
            </ul>
        `,
        originalLink: 'https://www.saltandlavender.com/one-pan-creamy-gnocchi-with-sausage/#wprm-recipe-container-10554'
    },
    {
        title: 'Crock-Pot Swedish Meatballs',
        ingredients: `
            <ul>
               <li> 1 (10 3/4 ounce) can cream of mushroom soup                                          </li>
               <li> 2 cups beef broth                                                                    </li>
               <li> 1 teaspoon garlic powder                                                             </li>
               <li> 1 Tablespoon Worcestershire Sauce                                                    </li>
               <li> 2 tablespoons Steak Sauce                                                            </li>
               <li> 1/2 teaspoon paprika                                                                 </li>
               <li> 1/2 teaspoon salt                                                                    </li>
               <li> 1/2 teaspoon pepper                                                                  </li>
               <li> 1 (28 ounce) bag frozen homestyle meatballs (this is about 1 3/4 pound of meatballs) </li>
               <li> 1 cup sour cream </li>
            </ul>
        `,
        instructions: `
            <ul>
                <li> In the crock pot or a large bowl, mix the cream of mushroom soup and beef broth with a whisk. </li>
                <li> Add remaining seasonings and stir in the frozen meatballs.                                    </li>
                <li> Cook on high for 4 hours.                                                                     </li>
                <li> Add the sour cream, mixing it well, and allow to cook for another 30 minutes.                 </li>
                <li> For a meal, serve over egg noodles.                                                           </li>
            </ul>
        `,
        originalLink: 'https://daybydayinourworld.com/crock-pot-swedish-meatballs/#mv-creation-2-jtr'
    },
    {
        title: 'Crock Pot Mississippi Meatballs',
        ingredients: `
            <ul>
                <li> 32 ounce bag frozen meatballs (I used Johnsonville homestyle) </li>
                <li> 1 ounce packet au jus gravy mix (see notes below)             </li>
                <li> 1 ounce packet ranch dressing mix (see notes below)           </li>
                <li> ½ cup water                                                   </li>
                <li> ¼ cup peperoncini juice (or beef broth, see my notes below)   </li>
                <li> ½ cup (1 stick) unsalted butter, sliced                       </li>
                <li> 8 pepperoncini peppers                                        </li>
            </ul>
        `,
        instructions: `
            <ul>
                <li> Place 32 ounce bag frozen meatballs into the bottom of a 6 quart slow cooker. </li>
                <li> Mix together 1 ounce packet au jus gravy mix, 1 ounce packet ranch dressing mix, 1/2 cup water and 1/4 cup peperoncini juice until combined. Pour on top of the meatballs and stir so they are coated. </li>
                <li> A glass measuring cup with au jus gravy mix, ranch dressing mix, water, and pepperoncini juice being poured over meatballs in a Crock Pot.                                                             </li>
                <li> Place 1/2 cup (1 stick) unsalted butter, sliced on top, scattered.                                                                                                                                     </li>
                <li> Cubed butter on top of meatballs in a slow cooker.                                                                                                                                                     </li>
                <li> Add 8 pepperoncini peppers (just scatter them around).                                                                                                                                                 </li>
                <li> Cubed butter and pepperoncinis on top of meatballs in a Crock Pot.                                                                                                                                     </li>
                <li> Cover and cook on low for 3-4 hours, try and stir them every 30 minutes or so, so they don’t burn. Serve immediately with the drippings as a gravy over the meatballs.                                 </li>
                <li> A serving spoon holding some Mississippi Meatballs.                                                                                                                                                    </li>
            </ul>
        `,
        originalLink: 'https://www.thecountrycook.net/crock-pot-mississippi-meatballs/#recipe'
    },
    {
        title: 'Slow Cooker Potato Soup',
        ingredients: `
            <ul>
                <li> 6 slices cooked bacon*, diced                                                                           </li>
                <li> 3–4 cups good-quality chicken or vegetable stock                                                        </li>
                <li> 2 pounds Yukon gold potatoes**, peeled (if desired) and diced                                           </li>
                <li> 1 medium white or yellow onion, peeled and diced                                                        </li>
                <li> 4 tablespoons bacon grease* (or butter)                                                                 </li>
                <li> 1/3 cup all-purpose flour                                                                               </li>
                <li> 1 (12-ounce) can 2% evaporated milk                                                                     </li>
                <li> 1 cup shredded reduced-fat sharp cheddar cheese                                                         </li>
                <li> 1/2 cup plain low-fat Greek yogurt or low-fat sour cream                                                </li>
                <li> 1 teaspoon Kosher salt, or more to taste                                                                </li>
                <li> 1/2 teaspoon freshly-cracked black pepper                                                               </li>
                <li> optional toppings: thinly-sliced green onions or chives, extra shredded cheese, extra bacon, sour cream </li>
            </ul>
        `,
        instructions: `
            <ul>
               <li> Add bacon, 3 cups chicken stock, potatoes and onion to the bowl of a large slow cooker, and stir to combine.  Cook on low for 6-8 hours or on high for 3-4 hours, or until the potatoes are completely tender and cooked through.                                                                                                                                                                                                                                                                                                                                                          </li>
               <li> Once the soup has slow cooked and is about ready to serve, cook the butter in a small saucepan on the stove over medium-high heat until it has melted.  Whisk in the flour until it is completely combined, and then cook for 1 minute, stirring occasionally.  Gradually add in the evaporated milk while whisking it together with the flour mixture, and continue whisking until the mixture is completely smooth.  Let the mixture continue cooking until it reaches a simmer, stirring occasionally, and then it should get really thick.                                             </li>
               <li> Immediately pour the milk mixture into the slow cooker with the potatoes, and stir until combined.  Add in the cheddar cheese, Greek yogurt (or sour cream), salt and pepper, and stir until combined.  If you would like the soup to be even thicker, you can use a potato masher or a large spoon to mash about half of the potatoes (while the soup is still in the slow cooker) to thicken the soup up.  If you would like the soup to be thinner, add in an extra 1-2 cups of warmed chicken or vegetable stock.  Stir to combine, then taste and add more salt and pepper if needed. </li>
               <li> Serve warm, garnished with desired toppings.  Or transfer to a sealed container and refrigerate for up to 3 days.  (This recipe will not freeze well.) </li>
            </ul>
        `,
        originalLink: 'https://www.gimmesomeoven.com/slow-cooker-potato-soup-recipe/#tasty-recipes-63837'
    },
    {
        title: 'Crock Pot Mississippi Pot Roast',
        ingredients: `
            <ul>
                <li> 2 Tablespoons olive oil (or vegetable oil) </li>
                <li> 2-3 pound chuck roast                      </li>
                <li> salt and pepper (to taste)                 </li>
                <li> 1 packet ranch dressing mix                </li>
                <li> 1 packet dry onion soup mix (or au jus gravy mix) </li>
                <li> ½ cup (1 stick) salted butter              </li>
                <li> 8 peperoncini peppers                      </li>
            </ul>
        `,
        instructions: `
            <ul>
                <li> Heat up a large skillet on high. Add 2 Tablespoons olive oil to hot skillet. You want it really hot here to brown or "sear" the beef quickly. This can also be done in your slow cooker if you have the sear or saute option. </li>
                <li> Take a paper towel and make sure you dry both sides of the 2-3 pound chuck roast. Season with a little bit of salt and pepper. Once the skillet is nice and hot, add the roast. Using tongs, flip the meat over and sear the other side of the roast for another 2-3 minutes. </li>
                <li> Transfer meat to slow cooker. Sprinkle 1 packet ranch dressing mix and 1 packet dry onion soup mix over pot roast. Top with 1/2 cup (1 stick) salted butter then place 8 peperoncini peppers on and around the roast.                                                         </li>
                <li> Cover and cook on low for 8 hours. DO NOT open the lid. It’s tempting but leave it alone!                                                                                                                                                                                     </li>
                <li> After it has cooked, take two forks and start shredding the meat. Discard any big fatty pieces. Then serve.                                                                                                                                                                   </li>
            </ul>
        `,
        originalLink: 'https://www.thecountrycook.net/crock-pot-mississippi-pot-roast/#recipe'
    },
    {
        title: 'Instant Pot Mississippi Pot Roast',
        ingredients: `
            <ul>
                <li> 3-6 lbs Chuck Roast, cut into 1 pound pieces of even thickness                      </li>
                <li> 3 Tablespoons Vegetable Oil                                                         </li>
                <li> 1 small Onion, chopped (optional, if you don't use the onion, omit the veg oil, too)</li>
                <li> 9 Pepperoncinis, whole or chopped                                                   </li>
                <li> ½ cup Pepperoncini Juice from the jar                                               </li>
                <li> 1 ½ cups Low Sodium Beef Broth, (or water)                                          </li>
                <li> 1 (1 oz) packet Dry Ranch Dressing Mix                                              </li>
                <li> 1 (1 oz) packet Au Jus (or packet of brown gravy mix)                               </li>
                <li> 4 Tablespoons Butter, unsalted                                                      </li>
            </ul>
            <h4>For Gravy</h4>
            <ul>
                <li> 3 Tablespoons Flour</li>
                <li> 3 Tablespoons Softened Butter, unsalted</li>
            </ul>
        `,
        instructions: `
            <ul>
                <li> Turn pot on Sauté mode (normal heat). When the display reads "Hot" add the oil.                                               </li>
                <li> When oil heats up, place meat in one layer into the pot and don't move it for 5 full minutes (Don't brown for less than that).</li>
                <li> Turn meat over and cook for another 5 full minutes.                                                                           </li>
                <li> Remove the meat to a dish and cover with foil to keep it warm.                                                                </li>
            </ul>
            <h4> Deglaze the Pot </h4>
            <ul>
                <li> Add the onion and a bit of the beef broth to the pot and stir to loosen the brown bits from the bottom of the pot. As you stir, scrape the bottom of the pot with a wooden spoon until the bottom is free of all of the stuck on brown bits.</li>
                <li> Add the pepperoncinis and their juice, beef broth, au jus and ranch packets, and butter. Stir to combine.                                                                                                                                   </li>
                <li> Turn off the sauté mode.                                                                                                                                                                                                                    </li>
            </ul>
            <h4> Pressure Cook the Roast </h4>
            <ul>
                <li> Add the meat back into the pot.                                                                                                                                </li>
                <li> Close lid and set the steam release knob knob to the Sealing position.                                                                                         </li>
                <li> Press the 'Pressure Cook' or 'Manual' button (or dial) then the +/- button to select 60 minutes. High Pressure. It will take several minutes to build pressure.</li>
                <li> When the cooking cycle ends, let the pot sit undisturbed and count up to 15 min (15 minute natural pressure release, or NPR).                                  </li>
                <li> After the 15 minute NPR, turn off the pot and manually release the remaining pressure by turning the steam release knob to the Venting position.               </li>
                <li> When the pin in the lid drops back down, open the lid.                                                                                                         </li>
                <li> Remove the pot roast to a plate and cover.                                                                                                                     </li>
            </ul>
            <h4> Make the Gravy </h4>
            <ul>
                <li> Turn the Sauté function back on.                                                                                                                  </li>
                <li> Mix the flour and softened butter together until well combined.                                                                                   </li>
                <li> When the liquid is starting to simmer, add the mixture to the pot, stirring well until thickened. Then turn off the pot and add the roast back in.</li>
                <li> Enjoy with mashed or baked potatoes, or over rice.                                                                                                </li>
            </ul>
        `,
        originalLink: 'https://www.simplyhappyfoodie.com/instant-pot-mississippi-pot-roast/#wprm-recipe-container-4374'
    },
    {
        title: 'Pulled Pork Pressure Cooker',
        ingredients: `
            <ul>
               <li> 4 lb pork butt, bone-in (or pork shoulder roast). 3 lb if boneless</li>
               <li> 2 tablespoon pork rub mix                                         </li>
               <li> 2 tablespoon avocado oil or olive oil                             </li>
               <li> ½ cup liquid (chicken broth, beef broth, apple or pineapple juice)</li>
               <li> 1 cup+ BBQ sauce                                                  </li>
               <li> optional- 1 teaspoon liquid smoke                                 </li>
            </ul>
        `,
        instructions: `
            <ul>
                <li> Preheat the Instant Pot to Saute High and add 2 tablespoon of oil to the pot.                                                                                                                                                                                                                                                                                                                                                                                                         </li>
                <li> Cut the pork roast into 4 large pieces. If it's boneless pork that's really easy to get similar-sized hunks of meat. If you have bone-in pork roast you can do this one of two ways: either leave the bone attached to one of the large chunks of meat OR carefully cut the bone off of the meat.                                                                                                                                                                                     </li>
                <li> Sprinkle the pork with the rub mix. Press the rub into the pork with clean hands to help make sure the seasoning stays on the pork butt and doesn't just fall to the bottom of the Instant Pot.                                                                                                                                                                                                                                                                                       </li>
                <li> Sear the pork roast pieces on each side for 3-4 minutes until brown. It'll be a snug fit in the pot, but all pork pieces will fit. Do not peek at the pork until you're ready to flip it over, you won't want to keep it from browning to a nice crisp. And be sure not to fry it on each side for too long, otherwise, the pork rub will start to burn.                                                                                                                              </li>
                <li> You'll need to deglaze the pot to avoid getting the burn signal during cooking. Press the Cancel button to stop the pot from cooking before you deglaze. To do this, remove the pork pieces and set them aside on a plate. Add ½ cup of liquid like chicken broth, beef broth, apple juice, or pineapple juice, and scrape up the brown bits on the bottom of the pot. Basically, just deglaze the pot with whatever liquid you're planning to add to help bring the pot to pressure. </li>
                <li> Add the 1 teaspoon of optional liquid smoke now if you're using it (optional). </li>
                <li> Add the pork back to the Instant Pot and pour ½ cup+ of BBQ sauce over the top. </li>
                <li> Turn the pot to Manual High for 60 minutes. Some pressure cookers call the manual setting Pressure Cook.  Natural release for 10 minutes before doing a quick release of the remaining pressure in the pot. Once the pin drops, you can open your lid. </li>
                <li> Let the pork cool down for 5-10 minutes before shredding it with forks, meat claws, or a mixer. Enjoy!  </li>
            </ul>
        `,
        originalLink: 'https://wholelottayum.com/instant-pot-pulled-pork-recipe/#mv-creation-175-jtr'
    },
    {
        title: 'Easy Meatball Recipe',
        ingredients: `
            <ul>
                <li> 2 lbs ground beef I use 80/20                  </li>
                <li> 2 eggs                                         </li>
                <li> 1 cup milk                                     </li>
                <li> 2 tablespoon grated parmesan cheese            </li>
                <li> ½ cup plain breadcrumbs                        </li>
                <li> 1 teaspoon garlic powder                       </li>
                <li> 1 teaspoon onion powder                        </li>
                <li> 1 teaspoon salt, seasoning salt or garlic salt </li>
                <li> ½ teaspoon black pepper </li>
            </ul>
        `,
        instructions: `
            <ul>
                <li> Preheat oven to 450°F. Line 2 rimmed baking sheets (baking pans will work if you don't have rimmed cookie sheets) with foil and set aside.                       </li>
                <li> Place all ingredients into a large bowl and gently mix together with your hands, careful not to overmix. Just squish it all together a few times to combine.     </li>
                <li> Using a cookie scoop or your hands, divide the meat mixture into desired-size meatballs. As long as they are uniform, they can be as large or small as you like. </li>
                <li> Bake in the preheated oven for 10-14 minutes, depending on the size. Be sure to check for doneness. Serve hot with your favorite sauce. </li>
            </ul>
        `,
        originalLink: 'https://lilluna.com/easy-homemade-meatballs/#wprm-recipe-container-98420'
    },
    {
        title: 'Classic Stuffed Shells',
        ingredients: `
            <ul>
                <li> 24 jumbo pasta shells cooked according to package directions </li>
                <li> 15 ounces ricotta cheese                                     </li>
                <li> 3 cups shredded mozzarella cheese divided use                </li>
                <li> 2 teaspoons Italian seasoning                                </li>
                <li> salt and pepper to taste                                     </li>
                <li> 1 egg                                                        </li>
                <li> 1/2 cup grated parmesan cheese                               </li>
                <li> 3 cups marinara sauce divided use                            </li>
                <li> 2 tablespoons parsley chopped                                </li>
                <li> cooking spray                                                </li>
            </ul>
        `,
        instructions: `
            <ul>
                <li> Preheat the oven to 375 degrees F. Coat a 9″x13″ baking pan with cooking spray.                                                                         </li>
                <li> Spread 1 1/2 cups of the marinara sauce in an even layer in the bottom of the pan.                                                                      </li>
                <li> Place the ricotta cheese, 1 1/2 cups of mozzarella cheese, Italian seasoning, egg, salt, pepper and parmesan cheese in a bowl. Stir to combine.         </li>
                <li> Fill each shell with the ricotta mixture and place in the baking dish.                                                                                  </li>
                <li> Spoon the remaining marinara sauce over the shells, then sprinkle the other 1 1/2 cups of cheese over the top.                                          </li>
                <li> Cover the dish with foil. Bake for 20 minutes. Uncover the pan, then bake for an additional 10 minutes or until cheese is melted and starting to brown. </li>
                <li> Sprinkle with parsley, then serve. </li>
            </ul>
        `,
        originalLink: 'https://www.dinneratthezoo.com/stuffed-shells-recipe/#wprm-recipe-container-26869'
    },
    {
        title: 'Green Chili Beef Enchiladas with White Sauce',
        ingredients: `
                <h4>Beef Filling</h4>
                <ul>
                    <li> 1 Tablespoon olive oil
                    <li> 1 pound ground beef*
                    <li> ½ white onion, diced, divided*
                    <li> 4 ounces (1 cup) cheddar cheese, shredded*
                    <li> 1 packet taco seasoning, prepare according to instructions on packet*
                </ul>
                <h4>Sour Cream Sauce</h4>
                <ul>
                    <li> 2 Tablespoons unsalted butter                   </li>
                    <li> 3 Tablespoons all purpose flour                 </li>
                    <li> 1 ¾ cups chicken broth                          </li>
                    <li> ½ teaspoon salt                                 </li>
                    <li> 1 teaspoon granulated garlic                    </li>
                    <li> 4 ounces (1 cup) monterey jack cheese, shredded </li>
                    <li> 8 ounces sour cream                             </li>
                    <li> 2 4 ounce cans green chiles                     </li>
                    <li> 1 lime, juiced                                  </li>
                    <li> ½ cup chopped cilantro                          </li>
                    <li> 10 6 inch flour tortillas                       </li>
                    <li> 4 ounces (1 cup) monterey jack cheese, shredded </li>
                </ul>
        `,
        instructions: `
            <ul>
                <li> Review all recipe notes and instructions before beginning. </li>
                <li> Preheat the oven to 400 degrees. In a large skillet, heat the olive oil over medium high heat. Add the ground beef and half of the diced onion. Cook until the meat is completely browned. Add the taco seasoning prepared according to the instructions on the packet. Reduce on medium low until most of the water has cooked off. Remove from the heat. Fold in the cheddar cheese. Set aside. </li>
                <li> In a medium saucepan, melt the butter over medium heat. Once the butter begins to bubble, whisk in the flour. Cook for 1-2 minutes.                                                                                                                                                                                                                                                               </li>
                <li> Add the chicken broth, remaining onion, salt, and garlic. Bring to a boil. Simmer on medium high heat until the sauce begins to thicken.                                                                                                                                                                                                                                                          </li>
                <li> Remove from the heat. Stir in 1 cup of monterey jack cheese until mostly melted. Stir in the sour cream, green chiles, lime juice, and cilantro.                                                                                                                                                                                                                                                  </li>
                <li> Lightly spray a 9 x 13 baking dish with non-stick spray. Ladle about 1 cup of the sour cream sauce into the bottom. Fill a tortilla with a couple spoonfuls of the beef. Roll tightly. Place the enchilada into the pan seam side down.                                                                                                                                                           </li>
                <li> Repeat with the remaining tortillas. Top with remaining sauce and 1 cup of monterey jack cheese. Bake for 20-25 minutes or until bubbling. Broil if desired for color.                                                                                                                                                                                                                            </li>
            </ul>
        `,
        originalLink: 'https://biteswithbri.com/green-chile-beef-enchiladas/#recipe'
    },
    {
        title: 'Creamy Cajun Chicken',
        ingredients: `
            <ul>
                <li> 2 large chicken breasts </li>
                <li> Salt & pepper to taste </li>
                <li> 1/4 teaspoon garlic powder </li>
                <li> 1/2 teaspoon + 1 tablespoon Cajun seasoning (use a no or low salt variety) </li>
                <li> Flour for dredging                                                         </li>
                <li> 2 tablespoons butter                                                       </li>
                <li> 1 tablespoon olive oil                                                     </li>
                <li> 3 cloves garlic minced                                                     </li>
                <li> 1/4 cup sun-dried tomatoes                                                 </li>
                <li> 1/4 cup chicken broth                                                      </li>
                <li> 1 cup heavy/whipping cream                                                 </li>
                <li> 1/2 cup freshly grated parmesan cheese                                     </li>
            </ul>
        `,
        instructions: `
                <ul>
                    <li> Cut the chicken in half lengthwise so you have four thinner cutlets. Sprinkle them with salt & pepper, garlic powder, and 1/2 teaspoon of the Cajun seasoning. Coat them in flour.                                                                                          </li>
                    <li> Add the butter and oil to a skillet over medium-high heat. Once the pan is hot, add the chicken. Cook it for 4-5 min/side or until it's nice and golden. Take the chicken out of the pan and set it aside.                                                                  </li>
                    <li> Add the minced garlic, sun-dried tomatoes, and chicken broth to the pan. Let it bubble for about 30 seconds or so.                                                                                                                                                          </li>
                    <li> Reduce the heat to medium and stir in the cream and remaining 1 tablespoon of Cajun seasoning.                                                                                                                                                                              </li>
                    <li> Add the chicken back into the pan and cook for another 5 minutes or so, until the sauce is thickened a bit and the chicken is cooked through. Sprinkle the parmesan cheese over top (or stir it into the sauce). Season with salt & pepper as needed and serve immediately. </li>
                </ul>
        `,
        originalLink: 'https://www.saltandlavender.com/creamy-cajun-chicken/#wprm-recipe-container-26889'
    },
    {
        title: 'Baked Ham and Cheese Sliders',
        ingredients: `
            <ul>
                <li> ¾ cup melted butter                   </li>
                <li> 1 ½ tablespoons Dijon mustard         </li>
                <li> 1 ½ teaspoons Worcestershire sauce    </li>
                <li> 1 ½ tablespoons poppy seeds           </li>
                <li> 1 tablespoon dried minced onion       </li>
                <li> 24 mini sandwich rolls                </li>
                <li> 1 pound thinly sliced cooked deli ham </li>
                <li> 1 pound thinly sliced Swiss cheese </li>
            </ul>
        `,
        instructions: `
            <ul>
                <li> Gather all ingredients.                                                                                                                                                                                                      </li>
                <li> Preheat the oven to 350 degrees F (175 degrees C). Grease a 9x13-inch baking dish.                                                                                                                                           </li>
                <li> Mix butter, Dijon mustard, Worcestershire sauce, poppy seeds, and dried onion in a bowl.                                                                                                                                     </li>
                <li> Separate the tops from the bottoms of the rolls; arrange the bottom pieces in a layer in the prepared baking dish. Layer about 1/2 of the ham onto the rolls; add a layer of Swiss cheese and top with remaining ham slices. </li>
                <li> Place the tops of the rolls onto the sandwiches. Pour mustard mixture evenly over the rolls </li> 
                <li> Bake in the preheated oven until the rolls are lightly browned and the cheese has melted, about 20 minutes. Slice into individual rolls through the ham and cheese layers to serve. </li>
            </ul>
        `,
        originalLink: 'https://www.allrecipes.com/recipe/216756/baked-ham-and-cheese-party-sandwiches/'
    },
    {
        title: 'Baked Feta Pasta',
        ingredients: `
            <ul>
                <li> 1 Pint of Cherry Tomatoes                                                  </li>
                <li> 1 8oz block of Feta Cheese or Crumbled Feta                                </li>
                <li> 2 tablespoons Olive Oil                                                    </li>
                <li> Salt and Pepper to Taste                                                   </li>
                <li> 8 oz of cooked Pasta (I used Cavatapi)                                     </li>
                <li> ½  cup of Heavy Cream (optional, see the section titled ingredients above) </li>
                <li> ¼ cup parmesan cheese                  </li>
                <li> 2 teaspoons of garlic                  </li>
                <li> 2 tablespoons of freshly chopped basil </li>
            </ul>
        `,
        instructions: `
            <ul>
                <li> Preheat the oven to 350 degrees.                                                                                                                                                                                                                                    </li>
                <li> Add cherry tomatoes to a casserole dish.                                                                                                                                                                                                                            </li>
                <li> Place the block of feta cheese in the center of the casserole dish. Drizzle olive oil over the cherry tomatoes and feta cheese and sprinkle with salt and pepper. Cook the tomatoes and feta in the oven for 40 minutes.                                            </li>
                <li> While the tomatoes and feta cheese are cooking in the oven, boil the noodles and set aside.                                                                                                                                                                         </li>
                <li> Take the casserole dish out of the oven and immediately start to mash the cherry tomatoes and cheese together in the dish. Stir in cream (optional), parmesan cheese, basil, garlic, and then the pasta. Continue stirring until everything is incorporated. Enjoy! </li>
            </ul>
        `,
        originalLink: 'https://www.recipe-diaries.com/baked-feta-pasta/#tasty-recipes-24881'
    },
    {
        title: 'Mini Meatloaf',
        ingredients: `
                <h4>Meatloaf</h4>
                <ul>
                    <li> 1 1/2 pounds lean ground beef (or ground turkey) </li>
                    <li> 2/3 cup panko bread crumbs , or regular breadcrumbs </li>
                    <li> 1/2 cup onion , chopped                             </li>
                    <li> 1 Tablespoon barbecue sauce                         </li>
                    <li> 1 Tablespoon mustard                                </li>
                    <li> 1/2 teaspoon garlic powder                          </li>
                    <li> 1 1/2 teaspoons chili powder                        </li>
                    <li> 1/2 teaspoon salt                                   </li>
                    <li> 1/2 teaspoon freshly ground black pepper            </li>
                    <li> 1 egg , lightly beaten                              </li>
                </ul>
                <h4>Meatloaf Sauce</h4>
                <ul>
                    <li> 1/3 cup light brown sugar  </li>
                    <li> 1/2 cup ketchup            </li>
                    <li> 2 teaspoon dijon mustard   </li>
                    <li> 1/4 teaspoon ground nutmeg </li>
                </ul>
        `,
        instructions: `
            <ul>
                <li> Preheat oven to 350 degrees F. </li>
                <li> In a large mixing bowl (using your hands, meat chopper, or a large spoon), combine ground beef, breadcrumbs, onion, 1 Tbsp barbecue sauce, mustard, chili powder, garlic powder, salt, pepper and egg. </li>
                <li> Divide the mixture among the 12 cups in a standard muffin tin, pressing them down to fill the cup. Bake for 15 minutes.                                                                                </li>
                <li>  In the meantime, in a small bowl mix together all of the ingredients for the sauce.                                                                                                                   </li>
                <li> Remove the meatloaf from oven and use a paper towel to soak up any grease on the meatloaf.                                                                                                             </li>
                <li> Generously spoon sauce over each mini meat loaf. Return to oven for an additional 10-15 minutes or until the meat is cooked through.                                                                   </li>
                <li> Serve with mashed potatoes and any extra sauce.                                                                                                                                                        </li>
            </ul>
        `,
        originalLink: 'https://tastesbetterfromscratch.com/light-mini-bbq-meatloaf/#recipe'
    },
    {
        title: 'Crispy Pork Carnitas (Mexican Slow Cooked Pulled Pork)',
        ingredients: `
            <ul>
                <li> 4 pounds (or 2 kg) skinless, boneless pork butt (or shoulder) </li>
                <li> 3-4 teaspoons salt                                            </li>
                <li> 1 teaspoon pepper                                             </li>
                <li> 1 tablespoon dried oregano (or Mexican oregano)               </li>
                <li> 1 tablespoon ground cumin                                     </li>
                <li> 1 large brown or white onion , cut into wedges                </li>
                <li> 8 cloves garlic , smashed                                     </li>
                <li> 2 limes , juiced                                              </li>
                <li> 2 large oranges , juiced (or 3/4 cup natural orange juice)    </li>
                <li> 3/4 cup coke (Original or Mexican coke is ideal)*             </li>
                <li> 2 bay leaves                                                  </li>
            </ul>
        `,
        instructions: `
            <ul>
               <li> Rinse and pat dry pork with a paper towel. </li>
               <li> In the bowl of a 6-quart slow cooker, add pork, salt, pepper, oregano, cumin, onion, garlic, lime juice, orange juice, coke, and bay leaves. </li>
               <li> Cover and cook on low heat setting for 8-10 hours, or high heat for 5-6 hours (until the meat falls apart).                                  </li>
               <li> Remove pork and shred with two forks (DO NOT DISCARD THE LIQUID!)                                                                            </li>
            </ul>
        `,
        originalLink: 'https://cafedelites.com/pork-carnitas-mexican-slow-cooked-pulled-pork/#recipe'
    },
    {
        title: 'Cast Iron Pizza',
        ingredients: `
            <ul>
                <li> 400g bread flour (14 ounces; about 2 1/2 cups), plus more for dusting                                </li>
                <li> 10g kosher salt (0.35 ounces; about 2 teaspoons), plus more for sprinkling                           </li>
                <li> 4g instant yeast (0.15 ounces; about 1 teaspoon), such as SAF Instant Yeast                          </li>
                <li> 275g water (9.5 ounces; about 1 cup plus 3 tablespoons)                                              </li>
                <li> 8g extra-virgin olive oil (0.25 ounces; about 2 teaspoons), plus more to coat pans and for drizzling </li>
                <li> 1 1/2 cups pizza sauce, such as our New York–style pizza sauce </li>
                <li> 12 ounces grated full-fat, low moisture (dry) mozzarella cheese (see note) </li>
                <li> Toppings as desired </li>
                <li> Small handful torn fresh basil leaves (optional) </li>
                <li> 2 ounces grated Parmesan or Pecorino Romano cheese (optional) </li>
            </ul>
        `,
        instructions: `
            <ul>
                <li> Combine flour, salt, yeast, water, and oil in a large bowl. Mix with hands or a wooden spoon until no dry flour remains. (The bowl should be at least 4 to 6 times the volume of the dough to account for rising.)                                                                                                                                                                                                                                                                                                                                                                                                                                 </li>
                <li> Cover bowl tightly with plastic wrap, making sure that the edges are well sealed, then let rest at cool room temperature (no warmer than 75°F) for at least 8 hours and up to 24. Dough should rise dramatically and fill bowl. In a hot kitchen, the dough may overproof near the end of that range.                                                                                                                                                                                                                                                                                                                                              </li>
                <li> Sprinkle top of dough lightly with flour, then transfer it to a well-floured work surface. Divide dough into 2 pieces and form each into a ball by holding it with well-floured hands and tucking the dough underneath itself, rotating it until it forms a tight ball.                                                                                                                                                                                                                                                                                                                                                                            </li>
                <li> Pour 1 to 2 tablespoons oil in the bottom of two 10-inch cast iron skillets or round cake pans (see note). Place 1 ball of dough in each pan and turn to coat evenly with oil. Using a flat palm, press dough around the pan, flattening it slightly and spreading oil around the entire bottom and edges of the pan. Cover tightly with plastic wrap and let dough sit at room temperature for 2 hours (at room temperatures above 75°F, the dough may require less time to rise; at temperatures below 65°F/18°C, it may require more time). After the first hour, adjust an oven rack to the middle position and preheat oven to 550°F (290°C). </li>
                <li> After 2 hours, dough should be mostly filling the pan up to the edges. Use your fingertips to press it around until it fills in every corner, popping any large bubbles that appear. Lift up one edge of the dough to let any air bubbles underneath escape, then repeat, moving around the dough until there are no air bubbles left underneath and the dough is evenly spread around the pan. </li> 
                <li> Top each round of dough with 3/4 cup sauce, spreading sauce to the very edge with the back of a spoon. Sprinkle evenly with mozzarella cheese, all the way to the edges. Season with salt. Add other toppings as desired. Drizzle with olive oil and scatter a few basil leaves over the top of each pizza, if desired. </li> 
                <li> Transfer pan to oven and bake until top is golden brown and bubbly and bottom is golden brown and crisp when you lift it with a thin spatula, 12 to 15 minutes. Immediately sprinkle with grated Parmesan or Pecorino Romano cheese, if using. Using a thin spatula, loosen pizza and peek underneath. If bottom is not as crisp as desired, place pan over a burner and cook on medium heat, moving the pan around to cook evenly until it is crisp, 1 to 3 minutes. Remove the pizzas and transfer to a cutting board. Cut each pizza into 6 slices and serve immediately. </li>
            </ul>
        `,
        originalLink: 'https://www.seriouseats.com/foolproof-pan-pizza-recipe'
    },
    {
        title: 'Philly Cheat Steak',
        ingredients: `
            <ul>
                <li> 1lb Ground Bison                                      </li>
                <li> 1 White Onion                                         </li>
                <li> 1 Green Belle Pepper                                  </li>
                <li> 3 tablespoons Avacado Oil                             </li>
                <li> 1/8 Cup Shredded Chedder Cheese (Shredded from block) </li>
                <li> 3-4 Tablespoons Salsa Con Queso </li>
                <li> 4 Slices Bread </li>
                <li> 2 Tablespoons Butter </li>
            </ul>
        `,
        instructions: `
            <ul>
                <li> Heat cast iron skillet to ~350F </li>
                <li> Put oil in the skillet </li>
                <li> Chop the bison and mix the onions and peppers into the cast iron skillet </li>
                <li> Cook the meat until parts get a crips edge </li>
                <li> Place both cheeses into the meat and mix meat until cheese is melted and blended </li>
                <li> Butter the bread on both sides                                                   </li>
                <li> Put the bread into a non stick skillet                                           </li>
                <li> Cook and flip until both sides are brown with a grilled cheese crunch            </li>
                <li> Place meat on bread and serve                                                    </li>
            </ul>
        `,
        originalLink: 'https://competent-darwin-fcf33c.netlify.app/dinners'
    },
    {
        title: 'Crockpot Ham',
        ingredients: `
            <ul>
                <li> 6-8 lbs Honey Cured, Spiral Cut, Pre-Cooked Ham </li>
                <li> 2 Cups Brown Sugar </li>
            </ul>
        `,
        instructions: `
            <ul>
                <li> Coat bottom of crockpot with 1 cup brown sugar.        </li>
                <li> Place ham in crockpot                                  </li>
                <li> Coat top of ham with brown sugar                       </li>
                <li> Cook on low for 8 - 10 hours (depending on size of ham)</li>
            </ul>
        `,
        originalLink: 'https://competent-darwin-fcf33c.netlify.app/dinners'
    },
    {
        title: 'Cacio e Pepe',
        ingredients: `
            <ul>
                <li> 8 oz Spaghetti or Spaghettoni Pasta </li>
                <li> 7 to 8 ounces about 1 cup Pecorino Romano Cheese, VERY FINELY grated (divide in two separate bowls)</li>
                <li> Freshly Ground Black Pepper, to taste                                                              </li>
                <li> Pinch of Salt (for pasta water)                                                                    </li>
            </ul>
        `,
        instructions: `
            <ul>
                <li> Toast the black peppercorns in a dry pan over low heat until you can smell the aromatic, smoky scent of black pepper (it should only take a couple minutes). Remove from the heat and grind the toasted peppercorns finely using a mortar and pestle (or simply use a pepper grinder) and set aside. </li>
                <li> Bring a pot of water to a boil. Add a pinch of salt (the cheese is already very salty) to the boiling water.  Add the spaghetti or spaghettoni pasta. Cook the pasta according to the package instructions or until it reaches your desired level of doneness. Keep an eye on it as it cooks, as it should be al dente, not overcooked. </li>
                <li> While the pasta is cooking grate the Pecorino Romano cheese in a very fine and fluffy texture                                                                                                                                                                                                                                           </li>
                <li> When the pasta is just about cooked to your liking. In the pot you will use to make the final dish, UNDER NO HEAT add some of the black pepper.  Add a few ounces of hot water (about 1 ½ oz per servings) from the pasta to warm up the pan and cool the water to a few degrees.                                                       </li>
                <li> In one of the cheese bowls, add about 2 to 3 tablespoons of the pasta water to create a “paste”.  The cheese should not exceed 180-190°F (82-88°C) to prevent it from breaking down.  Once the cheese has melted into the paste reserve for a few seconds.                                                                              </li>
                <li> Immediately transfer the cooked and drained pasta to the pan with the peppercorn. Add the cheese “paste” mix vigorously and toss the pasta in the creamy Cacio e Pepe sauce, ensuring it's evenly coated.                                                                                                                               </li>
                <li> Add enough of the rest of the cheese and continue tossing until the sauce thickens, the cheese melts and perfectly clings to the pasta. If needed, you can add a bit more of the reserved pasta water to adjust the consistency.                                                                                                        </li>
                <li> Serve your Cacio e Pepe pasta immediately, garnished with an extra sprinkle of freshly ground black pepper and a touch of Pecorino Romano cheese if desired.                                                                                                                                                                            </li>
                <li> Enjoy your perfectly creamy and indulgent Cacio e Pepe pasta! This classic Italian dish highlights the flavors of cheese and black pepper in every delightful bite. Serve it as a quick and elegant meal for two.                                                                                                                       </li>
                <li> And be sure not to tell any Italian (I’ll deny it) but at home I added some butter at the end!                                                                                                                                                                                                                                          </li>
            </ul>
        `,
        originalLink: 'https://chefjeanpierre.com/recipes/pasta/traditional-cacio-e-pepe-recipe/#recipe'
    },
    {
        title: 'Blackend Salmon Pasta',
        ingredients: `
            <ul>
                <li> 1 lb salmon (cut in 1 inch chunks)            </li>
                <li> 2 tbs avocado oil (divided)                   </li>
                <li> 1 ½ tsp Tonys Blackened Season + more to taste</li> 
                <li> 1 shallot (diced)                             </li>
                <li> 4 garlic cloves (minced)                      </li>
                <li> 2 tbs parsley (chopped)                       </li>
                <li> ¼ c white wine                                </li>
                <li> Juice of half a lemon                         </li>
                <li> 1 ½ c heavy cream                             </li>
                <li> 1-2 c spinach                                 </li>
                <li> ¾ c parmesan (freshly grated)                 </li>
                <li> 8 oz pappardelle pasta (boiled)               </li>
                <li> ⅓ reserved pasta water                        </li>
            </ul>
        `,
        instructions: `
            <ul>
                <li> Add 1 tbs of avocado oil to the salmon chunks and season with 1 ½ tsp of Tony Chachere’s Blackened Seasoning.                    </li>
                <li> In a large pan over medium-high heat, add the salmon and cook until it reaches your desired wellness and remove from the pan.    </li>
                <li> In the same pan with the drippings, add in the shallots. Saute for about 3-5 minutes or until translucent.                       </li>
                <li> Add in garlic and sauté for an additional minute.                                                                                </li>
                <li> Pour in white wine and lemon juice and let simmer until almost all the way evaporated.                                           </li>
                <li> Pour in heavy cream along with the reserved pasta water and simmer for about 5 minutes then add in spinach and cook until wilted.</li> 
                <li> Turn off the heat and add in ¾ c of parmesan. Stir until the cheese is melted.                                                   </li>
                <li> Add in your noodles and mix until well coated.                                                                                   </li>
                <li> Season to taste with more Blackened Seasoning, garnish with more parmesan and parsley.                                           </li>
            </ul>
        `,
        originalLink: 'https://www.youtube.com/watch?v=3D-R_QR__2M'
    },
    {
        title: 'Fettucine Alfredo',
        ingredients: `
            <h4>Fettuccine Alfredo Recipe 1/2 batch (as seen in the video):</h4>
            <ul>
                <li> 1/2 lbs dried Fettuccine pasta                              </li>
                <li> 4.7 oz or 134 grams Parmigiano Reggiano cheese finely grated</li>
                <li> 3.3 0z or 100 grams unsalted butter                         </li>
                <li> pasta water as necessary                                    </li>
            </ul>
            <h4> Fettuccine Alfredo Recipe full batch: </h4>
            <ul>
                <li> 1 lb dried Fettuccine pasta                                 </li>
                <li> 9.4 oz or 268 grams Parmigiano Reggiano cheese finely grated</li>
                <li> 6.6 0z or 200 grams unsalted butter                         </li>
                <li> pasta water as necessary                                    </li>
            </ul>
        `,
        instructions: `
            <ul>
                <li>Cook the pasta and don't drain the water</li>
                <li>In a non-stick pan -- melt the butter with a little pasta water so the butter doesn't burn</li>
                <li>Before noodles are completely cooked, start dipping the noodles out of the pasta water and in the non-stick</li>
                <li>Stir the noodles in with the butter and pasta water, and slowly incorporate the cheese</li>
                <li>Keep adding cheese until the noodles are propery coated</li>
                <li>Serve once pasta is cooked and cheese is melted</li>
            </ul>
        `,
        originalLink: 'https://www.youtube.com/watch?v=c7OgQshdq3g'
    },
    {
        title: 'Detroit Style Pizza',
        ingredients: `
        <h4>DOUGH</h4>
        <h5> BAKER’S PERCENTAGES </h5>
        <ul>
            <li> 95% White Bread Flour   </li>
            <li> 5% Whole Wheat Flour    </li>
            <li> 75% Water               </li>
            <li> 1% Diastatic Malt Powder</li>
            <li> 2% Salt                 </li>
            <li> 0.5% Instant Dry Yeast  </li>
        </ul>
        <h5>AMOUNTS FOR ONE 8”X10” PIZZA</h5>
        <ul>
            <li> 125g White Bread Flour (1.0 Cups)                                                                              </li>
            <li> 7g Whole Wheat Flour (3/4 Tbsp)                                                                                </li>
            <li> NOTE: If not using whole wheat flour, just use 132g of bread flour                                             </li>
            <li> 99g Water (3.3 fl oz)                                                                                          </li>
            <li> 1.3g Diastatic Malt Powder (1/2 tsp)                                                                           </li>
            <li> 2.6g Salt (volume can vary - check your salt container for conversions)                                        </li>
            <li> 0.66g Instant Dry Yeast (1/4 tsp)                                                                              </li>
            <li> TOTAL DOUGH WEIGHT: 235g                                                                                       </li>
            <li> I recommend 149g of Wisconsin brick cheese and 117g of sauce per pizza, but you can adjust to suit your tastes.</li>
        </ul>
        <h5>AMOUNTS FOR ONE 10”X14” PIZZA</h5>
        <ul>
            <li> 219g White Bread Flour (1.75 Cups)                                                                              </li>
            <li> 12g Whole Wheat Flour (1 1/3 Tbsp)                                                                              </li>
            <li> NOTE: If not using whole wheat flour, just use 231g of bread flour                                              </li>
            <li> 173g Water (5.8 fl oz)                                                                                          </li>
            <li> 2.3g Diastatic Malt Powder (7/8 tsp)                                                                            </li>
            <li> 4.6g Salt (volume can vary - check your salt container for conversions)                                         </li>
            <li> 1.15g Instant Dry Yeast (3/8 tsp)                                                                               </li>
            <li> TOTAL DOUGH WEIGHT: 411g                                                                                        </li>
            <li>                                                                                                                 </li>
            <li> I recommend 261g of Wisconsin brick cheese and 205g of sauce per pizza, but you can adjust to suit your tastes. </li>
        </ul>
        <h5>FOR ANY OTHER SIZE PAN</h5>
        <ul>
            <li> Use my Free Recipe Spreadsheet to calculate a custom recipe                                                                                                                                                                                                             </li>
            <li> OR, to calculate manually:                                                                                                                                                                                                                                              </li>
            <li> Use 1.58g of flour per sq. in. of pan area.                                                                                                                                                                                                                             </li>
            <li> Once you have your flour amount, just multiply that number by each bakers percentage to get the amounts for each ingredient.                                                                                                                                            </li>
            <li> i.e. if you determine that you need 120g of flour, multiply 120*0.65 to get your water amount, 120*0.02 to get your salt amount, and 120*0.013 to get your yeast amount. (In this example, you would use 120g of flour, 78g of water, 2.4g of salt, and 1.6g of yeast.) </li>
        </ul>
        <h4>SAUCE</h4>
        <h5>IF USING RETAIL TOMATOES (28 OZ CAN)</h5>
        <ul>
            <li> 1 28oz Can of Tomatoes
            <li> 3g Salt (volume can vary - check your salt container for conversions)
            <li> 1 tsp Dried Oregano
            <li> 1/2 tsp Dried Thyme 
            <li> 1/4 tsp Garlic Powder
            <li> 1/4 tsp Red Pepper Flakes
        </ul>
        <h5>IF USING COMMERCIAL TOMATOES (~102OZ CAN)</h5>
        <ul>
            <li> 1 102oz Can of Tomatoes                                                                    </li>
            <li> 11g Salt, plus more to taste (volume can vary - check your salt container for conversions) </li>
            <li> 3 1/2 tsp Dried Oregano                                                                    </li>
            <li> 1 3/4 tsp Dried Thyme                                                                      </li>
            <li> 1 tsp Garlic Powder                                                                        </li>
            <li> 1 tsp Red Pepper Flakes                                                                    </li>
        </ul>
        <h4>CHEESE</h4>
        <ul>
            <li> Wisconsin Brick Cheese, grated (exact amount depends on the size and number of pizzas you’re making)                        </li>
            <li> Available at some grocery stores and food service stores, but you can also purchase it online here                          </li>
            <li> If you can’t find Wisconsin brick cheese, I recommend a blend of 40% Muenster, 40% Monterey Jack, and 20% Mild White Cheddar</li>
        </ul>
        `,
        instructions: `
            <h4>MAKING THE DOUGH</h4>
            <ul>
                <li> In a large bowl, mix all ingredients, except for the salt, until thoroughly incorporated. Cover the bowl and let the dough rest for 20 minutes.                                                                                                                                                                                                                                            </li>
                <li> Add the salt, and work it into the dough.                                                                                                                                                                                                                                                                                                                                                  </li>
                <li> Cover the bowl, and allow the dough to rise until grown in size by about 50%. In total, it should take about 1-2 hours for the dough to grow by 50% but during this time, come back every 20 minutes to perform a set of stretch and folds (perform a maximum of 4 sets - if the dough still hasn’t grown by 50% after the 4th set, just let it rest at room temperature until it has).    </li>
                <li> Transfer the dough to your refrigerator to rest for anywhere between 1-4 days (2 or 3 days is ideal).                                                                                                                                                                                                                                                                                      </li>
                <li> On the morning of baking (about 3-4 hours before you would like to bake), prepare your Detroit-style pizza pans by coating them in a thin layer of a neutral oil.                                                                                                                                                                                                                          </li>
                <li> Remove the dough from the refrigerator, and divide it into the desired number of portions, form each one into a taught ball, and place it into its own pizza pan.                                                                                                                                                                                                                          </li>
                <li> Stretch the dough to the edges of the pan (it will likely shrink back toward the center, but that is okay.)                                                                                                                                                                                                                                                                                </li>
                <li> Cover the dough and allow it to rest for about 30 minutes, then stretch the dough further to the edges. Repeat this process every 30 minutes until the dough stays in the corners of the pan.                                                                                                                                                                                              </li>
                <li> Cover the dough and allow it to rise until it is soft and airy (about 2X-3X in size from when you removed it from the refrigerator), which should take around 1-2 more hours.                                                                                                                                                                                                              </li>
                <li> When the dough is about 30 minutes away from being ready, preheat your oven to its highest possible temperature, and set your oven rack to the middle setting.                                                                                                                                                                                                                             </li>
                <li> Par-top the dough, first with a layer of pepperoni (if using), then a very light layer of cheese, being sure not to place too much around the edges yet. Bake for 7-8 minutes at your oven’s highest temperature.                                                                                                                                                                          </li>
                <li> Remove the pizza from the oven and top the rest of the way, first by placing down the rest of the cheese, mostly around the edges, followed by the sauce in a “racing stripe” pattern, followed by any toppings you are using.                                                                                                                                                             </li>
                <li> Finish baking the pizza, still at your oven’s highest temperature, for another 4-5 minutes until the cheese edges are browned to your liking.                                                                                                                                                                                                                                              </li>
                <li> Remove the pizza from the pan as soon as possible after removing it from the oven, and ideally, place it onto a wire rack to cool for a few minutes before cutting into it.                                                                                                                                                                                                                </li>
            </ul>
            <h4>MAKING THE SAUCE</h4>
            <ul>
                <li> Combine all ingredients and blend thoroughly. Transfer to a heavy saucepan or pot (ideally stainless steel), and simmer until reduced by about 25-50% (depending on the initial thickness of your tomatoes. You’ll know it’s ready when there is very little moisture remaining in the sauce, and you can leave a streak in the pan when you run a spoon through it. </li>
                <li> The sauce can be stored in the refrigerator for up to 3 days before using. </li>
            </ul>
        `,
        originalLink: 'https://www.charlieandersoncooking.com/recipes/ultimate-detroit-style-pizza'
    },
    {
        title: 'Black Bean Quesadillas',
        ingredients: `
            <ul>
                <li> 1 (19-ounce) can no salt added black beans , rinsed and drained                                                                                 </li>
                <li> 2 teaspoons taco seasoning, or to taste                                                                                                         </li>
                <li> ½ teaspoon ground chipotle chili pepper or smoked paprika                                                                                       </li>
                <li> ½ cup fresh corn kernels, or thawed and drained frozen corn kernels or drained canned corn kernels                                              </li>
                <li> ⅓ cup red onion, peeled and minced                                                                                                              </li>
                <li> ⅓ cup salsa                                                                                                                                     </li>
                <li> ½ lime, juiced                                                                                                                                  </li>
                <li> 2 to 3 tablespoons fresh cilantro, chopped                                                                                                      </li>
                <li> 6 ounces shredded jalapeno Monterey Jack Cheese (or regular), divided (about 1.5 cups. Alternatively, use your favourite shredded vegan cheese) </li>
                <li> 6 flour tortillas, about 7 to 8 inches in diameter each                                                                                         </li>
                <li> 3 teaspoons olive oil or vegetable oil, approximately                                                                                           </li>
                <li> Guacamole, salsa, sour cream or plain Greek yogurt, for serving (optional)                                                                      </li>
            </ul>
        `,
        instructions: `
            <ul>
                <li> Preheat your oven to 200 degrees Fahrenheit (that way you can keep your cooked quesadillas warm as you are cooking them in batches).                                                                                                                             </li>
                <li> To make black bean quesadillas, place beans in a medium bowl and mash just a tad with a potato masher (leaving most beans whole while just a little mashed).                                                                                                     </li>
                <li> To beans, add taco seasoning, chipotle chili pepper, corn kernels, red onion, salsa, lime juice and cilantro. Stir to combine.                                                                                                                                   </li>
                <li> Distribute half of the Monterey Jack cheese (3 ounces or ¾ cup) among one half of each tortilla.                                                                                                                                                                 </li>
                <li> Then distribute the black bean filling over top of cheese. Top with the remaining 3 ounces or ¾ cup of cheese. Fold over each tortilla so it's a half-moon shape.                                                                                                </li>
                <li> To cook quesadillas, heat 1 teaspoon of olive oil or vegetable oil in a large non-stick frying pan over medium-low to medium heat. Gently swirl the oil around the pan to coat.                                                                                  </li>
                <li> Note: Cook the quesadillas in batches, two quesadillas at a time, adding more oil to the pan between batches.                                                                                                                                                    </li>
                <li> Place two quesadillas in the pan. Cook quesadillas for about 2 to 3 minutes on one side.                                                                                                                                                                         </li>
                <li> Flip the quesadillas to the other side and continue to cook for another 2 to 3 minutes or until they're golden brown and crispy on the outside, the inside is heated through, and the cheese is melted. Watch that they don't burn, because they can cook quickly.</li>
                <li> Note: You can place the cooked quesadillas onto a sheet pan in a preheated oven to keep warm while you are finishing cooking the remaining quesadillas.                                                                                                          </li>
                <li> Repeat process for cooking remaining quesadillas.                                                                                                                                                                                                                </li>
                <li> Once done, cut each quesadilla in half so you have 12 pieces. Serve with your favourite sauces for dipping. Enjoy!                                                                                                                                               </li>
                <li> Note: If you have any leftovers, properly store in an airtight container in the refrigerator and use within 3 to 4 days, reheating upon enjoying.                                                                                                                </li>
            </ul>
        `,
        originalLink: 'https://girlheartfood.com/black-bean-quesadillas/#recipe'
    },
    {
        title: 'Slow Cooker Beef Stroganoff',
        ingredients: `
            <ul>
                <li> 2 ½ pounds stew meat, cut into 1-inch cubes        </li>
                <li> 1 teaspoon garlic salt                             </li>
                <li> ½ teaspoon black pepper                            </li>
                <li> ¼ cup flour                                        </li>
                <li> 3-6 tablespoons olive oil                          </li>
                <li> 3 Tablespoons cold butter, separated               </li>
                <li> ½ cup white wine, see notes                        </li>
                <li> 16 oz. button Mushrooms                            </li>
                <li> 1 small yellow onion, diced                        </li>
                <li> 4 cloves garlic, minced                            </li>
                <li> 4 cups beef broth                                  </li>
                <li> 1 tsp better than bouillon, or 1 beef bouillon cube</li>
                <li> 2 Tablespoons red wine vinegar                     </li>
                <li> 2 Tablespoons Worcestershire sauce                 </li>
                <li> 2 Tablespoons Dijon mustard                        </li>
            </ul>
            <h4>To Thicken</h4>
            <ul>
                <li> ¼ cup cornstarch + ¼ cup cold water      </li>
                <li> 1 ½ cups Sour Cream, not reduced fat     </li>
                <li> 10.5 oz. cream of mushroom soup, optional</li>
            </ul>
            <h4>For Serving</h4>
            <ul>
                <li>1 pound wide homestyle Egg Noodles</li>
            </ul>
        `,
        instructions: `
            <ul>
                <li> Cut the beef into one-inch cubes and pat completely dry. Discard any large pieces of fat. Fat that’s marbleized into the meat is fine!                                                                                                                                                                                   </li>
                <li> Season with garlic salt and pepper. Sprinkle with flour and toss to coat.                                                                                                                                                                                                                                                </li>
                <li> Heat 2 Tablespoons olive oil in a skillet over medium-high heat. Sear the meat in batches for 45 seconds per side, until browned but not cooked through. Use kitchen tongs to carefully remove and set aside on a plate. Add more olive oil between batches as needed and decrease heat slightly if the pan gets too hot.</li>
                <li> Add 1 Tablespoon butter and white wine to the pot over medium heat. Use a silicone spatula to “clean” (deglaze) the bottom of the pan. Add the onions and mushrooms and cook for 4 minutes. Add the minced garlic and cook for 1 more minute. Remove from heat.                                                          </li>
                <li> Add the beef broth, bouillon, red wine vinegar, Worcestershire sauce, and Dijon mustard to the Slow Cooker and stir to combine. Add the beef as well as any juice that may have dripped onto the plate. Add the onions/mushrooms/garlic.                                                                                 </li>
                <li> Cook on high for 4-5 or on low for 8-9 hours.                                                                                                                                                                                                                                                                            </li>
                <li> Combine the cornstarch/cold water mixture and whisk it into the Slow Cooker.                                                                                                                                                                                                                                             </li>
                <li> Flip to warm.                                                                                                                                                                                                                                                                                                            </li>
                <li> In a medium bowl, whisk sour cream with 1 cup liquid from the slow cooker until smooth. Add to Slow Cooker and stir carefully to combine.                                                                                                                                                                                </li>
                <li> Stir in the condensed cream of mushroom soup if desired. (My homemade version adds even more thickness and flavor.)                                                                                                                                                                                                      </li>
                <li> Finally, turn heat off and swirl in 2 Tablespoons of cold butter. This adds a smooth, velvety finish.                                                                                                                                                                                                                    </li>
                <li> Serve over egg noodles. If desired, add cooked/drained egg noodles right to the Slow Cooker, stir to combine, and serve!                                                                                                                                                                                                 </li>
            </ul>
        `,
        originalLink: 'https://thecozycook.com/slow-cooker-beef-stroganoff/#wprm-recipe-container-34651'
    },
    {
        title: 'Kalua Pork',
        ingredients: `
            <ul>
                <li> 3-5 lb pork butt or shoulder, it’s the same thing </li>
                <li> 2-3 tablespoons Hawaiian Alea Salt or Kosher Salt </li>
                <li> 2-3 tablespoon liquid smoke                       </li>
                <li> 8-10 Garlic Cloves (halved)</li>
            </ul>
        `,
        instructions: `
            <ul>
                <li> Preheat oven to 325 </li>
                <li> Cover pork with liquid smoke and apply salt generously                                                                                                               </li>
                <li> Cut a 1 inch hole into the pork and shove a garlic halve into the hole. Do this for each halve.</li>
                <li> Cover in tin foil and place on baking sheet cook for or 4 1/2 - 5 hours, until approx 200-205 degrees                                                                </li>
                <li> Serve everyway you can think: in a sandwich with crispy cabbage, as a taco, the filling for a cheesy quesadilla or in place of the ham on a benedict – it’s all good </li>
            </ul>
        `,
        originalLink: 'https://www.thecookingguy.com/recipes/kalua-pork'
    },
    {
        title: 'Oven Baked Ribs',
        ingredients: `
            <h4>For the Ribs</h4>
            <ul>
                <li> 2 to 2 ½ pounds baby back pork ribs </li>
                <li> Salt and black pepper               </li>
            </ul>
            <h4>For the Sweet & Spicy BBQ Sauce</h4>
            <ul>
                <li> 1 tablespoon olive oil                    </li>
                <li> 1/4 cup finely diced onion                </li>
                <li> 1/2 teaspoon ground cumin                 </li>
                <li> 1/2 cup ketchup, try homemade ketchup     </li>
                <li> 1 tablespoon hot chili sauce like Sriracha</li>
                <li> 2 tablespoons light brown sugar           </li>
                <li> 1 tablespoon apple cider vinegar          </li>
                <li> Salt and ground pepper, to taste          </li>
            </ul>
        `,
        instructions: `
            <h4>Bake the Ribs</h4>
            <ul>
                <li> 1Preheat the oven to 275°F (135°C). Set aside a large roasting pan or rimmed baking sheet and foil.
                <li> 2If your ribs still have the thin membrane covering the back of the rack, remove it. The tips section below explains how.
                <li> 3Season both sides of the ribs generously with salt and pepper. If you are using a spice rub, rub it over the ribs.
                <li> 4Place the ribs, meatiest-side up, in a large roasting pan. You may need to cut the ribs in half to fit them into the pan. Cover the pan or baking sheet tightly with aluminum foil. Alternatively, wrap the rack in tight foil packets and place them on the baking sheet.
                <li> 5Bake the ribs until the meat falls easily from the bones, 2 ½ to 3 ½ hours. I check the ribs after 2 hours to see how they’re doing. Ribs are ready to come out of the oven when the meat is cooked through and tender. To check, pierce them with a sharp knife. There should be little to no resistance. You can also gently bend the ribs. If the meat looks like it will easily pull away from the bone, they’re ready.
            </ul>
            <h4>Make Homemade BBQ Sauce</h4>
            <ul>
                <li> 1While the ribs bake in the oven, make the barbecue sauce. Heat the olive oil in a saucepan over medium heat. Add the onions and cook until soft and translucent, 5 to 8 minutes.
                <li> 2Stir in the cumin and cook for an additional 30 seconds.
                <li> 3Add the ketchup, hot chili sauce, brown sugar, and apple cider vinegar. Stir to combine, season with salt, then cook for 2 minutes. Set aside while the ribs finish baking.
            </ul>
            <h4>To Finish</h4>
            <ul>
                <li> 1Remove the ribs from the oven, discard the aluminum foil, and generously brush both sides with barbecue sauce.                                                                                                                             </li>
                <li> 2(Optional) Move an oven rack near the top of the oven. Turn the broiler to high and broil the ribs until the barbecue sauce begins to caramelize, 3 to 4 minutes. Keep a close eye on the ribs while they broil so the sauce does not burn.</li>
            </ul>
        `,
        originalLink: 'https://www.inspiredtaste.net/7179/sweet-and-spicy-oven-baked-ribs/'
    }
];