export const brunch = [
    {
        title: 'Old English Sausage Muffins',
        ingredients: `
            <ul>
                <li> 1 lb breakfast sausage </li>
                <li> 2 (5-oz) jars Kraft Old English Cheese spread </li>
                <li> 2 tsp Worcestershire sauce                    </li>
                <li> ½ tsp garlic powder                           </li>
                <li> ¼ tsp cayenne powder, or to taste             </li>
                <li> 8 English muffins, split                      </li>
            </ul>
        `,
        instructions: `
            <ul>
                <li> Preheat oven to 400ºF. </li>
                <li> In a skillet, cook sausage until no longer pink and crumbled. Drain fat. </li> 
                <li> Add cheese spread, Worcestershire sauce, garlic powder, and cayenne pepper. Cook on medium-low until the cheese has melted. </li>
                <li> Place the English muffin halves on a cookie sheet. Top each half with some of the sausage mixture.                          </li>
                <li> Bake for 12 to 15 minutes, until golden and bubbly.                                                                         </li>
            </ul>
        `,
        originalLink: 'https://www.plainchicken.com/old-english-sausage-muffins/'
    },
    {
        title: 'Bagels',
        ingredients: `
            <ul>
                <li> 1 1/4 cups warm water (between 110 and 120 degrees), perhaps more </li>
                <li> 1 1/2 tablespoons sugar                                           </li>
                <li> 1 packet active dry yeast                                         </li>
                <li> 3 1/2 cups bread flour - plus a bit more maybe for kneading       </li>
                <li> 2 teaspoons salt                                                  </li>
                <li> Toppings: sesame seeds, poppy seeds, everything bagel seasoning   </li>
            </ul>
        `,
        instructions: `
            <ul>
                <li> Put yeast & sugar in a small bowl and add 1/2 cup of the warm water, stir and let sit until foamy - about 5 minutes                                                              </li>
                <li> Meanwhile, put the flour & salt in a large bowl - mix to combine                                                                                                                 </li>
                <li> Make a well in the flour, and pour in the yeast and remaining ¾ cup water                                                                                                        </li>
                <li> Bring together with your hand into one large, messy ball, spread out a little flour on your counter, and turn out the dough onto it                                              </li>
                <li> Knead 5-10 minutes or until it becomes a smooth ball                                                                                                                             </li>
                <li> Put dough into a lightly oiled bowl and cover with a damp towel, let rise in a relatively warm place about an hour                                                               </li>
                <li> Punch down with your fingers, put back on your counter (using a tiny bit more flour if necessary) and divide into 8 pieces, then shape each into a round ball                    </li>
                <li> Use your thumb and middle finger to create a hole in the middle of dough ball, then stretch out slightly to about an inch                                                        </li>
                <li> Place each on a lightly oiled baking sheet, then cover with a damp towel - let sit 10 minutes                                                                                    </li>
                <li> Preheat your oven to 425 and bring a big pot of water to simmer and gently slip the bagels into the water for 90 seconds a side - but don’t overcrowd them or they stick together</li>
                <li> Remove boiled bagels, put on a parchment lined baking sheet and this is the time to put whatever you want on top of them                                                         </li>
                <li> Bake 20 - 25 minutes, or until golden brown then cool on a rack                                                                                                                  </li>
            </ul>
        `,
        originalLink: 'https://www.thecookingguy.com/recipes/lox-bagels-smoked-cream-cheese'
    }
]