import { LitElement, css, html } from "lit";
import {unsafeHTML} from 'lit/directives/unsafe-html.js'
import DOMPurify from 'dompurify';
import './app-toggle-button.js'
import '../styles/spacing-variables.css'
import '../styles/font-variables.css'

export class RecipeCard extends LitElement {
    static get Properties() {
        return {
            id: { type: String },
            title: { type: String },
            description: { type: String },
            instructions: { type: String },
            ingredients: { type: String },
            originalLink: { type: String }
        }
    }
    
    static styles = css`
        .app-recipe-card {
            display: flex;
            flex-direction: column;
            margin-top: var(--spacing-xl);
            margin: 24px 32px 64px 32px;
            border-top: solid #333 var(--spacing-xxs);
            font-size: var(--font-size-lg);
            color: #333;
        }
        
        .app-recipe-card--title {
            display: flex;
            align-items: center;
        }

        .app-recipe-card--content {
            display: none;
            flex-direction: column;
            margin-left: var(--spacing-xl);
        }

        .ingredients, .instructions {
            h2, h3, h4, h5 {
                margin-bottom: 0;
            }

            h4, h5 {
                margin-left: var(--spacing-lg);
            }
            ol, ul {
                margin-top: 0;
                margin-bottom: var(--spacing-md);
                margin-left: var(--spacing-md);
                list-style: circle;
            }
        }

        .ingredients-title, .instructions-title {
            margin-left: 0;
        }

        a {
            word-wrap: break-word;
        }


        /* Extra small devices (phones, 600px and down) */
        @media only screen and (max-width: 600px) {
            .app-recipe-card {
                font-size: var(--font-size-sm);
                margin-top: var(--spacing-md);
            }

            .app-recipe-card--content {
                display: none;
                margin-left: var(--spacing-sm);
            }

            .ingredients, .instructions {
                h3 {
                    margin-left: 0;
                }
                h4, h5 {
                    margin-left: var(--spacing-xs);
                }
                ol, ul {
                    margin-left: var(--spacing-sm);
                }
            }
        }
        
        /* Extra large devices (large laptops and desktops, 1200px and up) */
        @media only screen and (min-width: 1200px) {
            .app-recipe-card {
                margin: 96px;
                font-size: var(--font-size-xl);
            }

        } 
    `

    constructor() {
        super();
        this.id = '';
        this.title = '';
        this.description = '';
        this.instructions = '' 
        this.ingredients = ''
        this.originalLink = ''
    }

    render() {
        const ingredients = unsafeHTML(DOMPurify.sanitize(this.ingredients, {FORBID_TAGS: ['script', 'a'] }))
        const instructions = unsafeHTML(DOMPurify.sanitize(this.instructions, {FORBID_TAGS: ['script', 'a'] }))
        return html`
            <div class="app-recipe-card">
                <div class="app-recipe-card--title">
                    <app-toggle-button .isNavbar=${false} @checked=${this.toggleContent}>
                        <h1>${this.title}</h1>
                    </app-toggle-button>
                </div>
                <div class="app-recipe-card--content">
                    <div class="ingredients">
                        <h3 class="ingredients-title">Ingredients:</h3>
                        ${ingredients}
                    </div>
                    <div class="instructions">
                        <h3 class="instructions-title">Cooking Steps:</h3>
                        ${instructions}
                    </div>
                    <div>
                        <h4>Original Link:</h4><a href="${this.originalLink}" target="_blank">${this.originalLink}</a>
                    </div>
                </div>
            </div>

        `
    }

    toggleContent(e) {
        this.isMenuOpened = e;
        const navPanel = this.shadowRoot.querySelector('.app-recipe-card--content')
        const isClicked = e.detail.value;

        if (isClicked) {
            navPanel.style.display = 'flex'
        } else {
            navPanel.style.display = 'none'
        }
    }
}

customElements.define('app-recipe-card', RecipeCard);