import { LitElement, html, css } from "lit-element";
import { outlet } from "lit-element-router";

export class Main extends outlet(LitElement) {
  outlet() {
    Array.from(this.querySelectorAll(`[route]`)).map((active) => {
        active.style.display = "none";
    });
    Array.from(this.shadowRoot?.querySelectorAll(`[route]`) || []).map((active) => {
        active.style.display = "none";
    });
    if (this.activeRoute) {
        Array.from(this.querySelectorAll(`[route~=${this.activeRoute}]`)).map((active) => {
            active.style.display = "";
        });
        Array.from(this.shadowRoot?.querySelectorAll(`[route~=${this.activeRoute}]`) || []).map((active) => {
            active.style.display = "";
        });
    }
  }

  render() {
    return html`
      <slot></slot>
    `;
  }
}

customElements.define("app-main", Main);