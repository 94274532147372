import { LitElement, html, css } from 'lit';
import {unsafeHTML} from 'lit/directives/unsafe-html.js'
import DOMPurify from 'dompurify';
import "./app-recipe-card.js";
import './app-toggle-button.js';
import '../styles/spacing-variables.css';
import '../styles/font-variables.css';

class Search extends LitElement {
  static properties = {
    items: { type: Array },
    query: { type: String },
    checkedItems: { type : Array }
  };

  constructor() {
    super();
    this.items = [];
    this.query = '';
    this.checkedItems = ['title'];
  }

  static styles = css`
          .app-recipe-card {
            display: flex;
            flex-direction: column;
            margin-top: var(--spacing-xl);
            margin: 24px 32px 64px 32px;
            border-top: solid #333 var(--spacing-xxs);
            font-size: var(--font-size-lg);
            color: #333;
        }
        
        .app-recipe-card--title {
            display: flex;
            align-items: center;
        }

        .app-recipe-card--content {
            display: none;
            flex-direction: column;
            margin-left: var(--spacing-xl);
        }

        .ingredients, .instructions {
            h2, h3, h4, h5 {
                margin-bottom: 0;
            }

            h4, h5 {
                margin-left: var(--spacing-lg);
            }
            ol, ul {
                margin-top: 0;
                margin-bottom: var(--spacing-md);
                margin-left: var(--spacing-md);
                list-style: circle;
            }
        }

        .ingredients-title, .instructions-title {
            margin-left: 0;
        }

        .input-search-container {
            position: relative;
            margin-top: var(--spacing-xxxl);
            margin-left: var(--spacing-xl);
            font-family: Arial, sans-serif;
        }
        
        .input-checkboxes__titles,
        .input-checkboxes__ingredients,
        .input-checkboxes__instructions,
        h3 {
            display: flex;
            align-items: center;
            margin-left: var(--spacing-xl);
        }

        /* Minimal black and white style for radio buttons */
        .input-checkboxes label {
            color: black;
            display: inline-block;
            cursor: pointer;
            position: relative;
            margin: 12px 0 0 8px;
        }

        /* Adjust styling for radio buttons */
        .input-checkboxes input[type="radio"] {
            display: none; /* Hide the actual radio buttons */
        }

        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 20px;
            width: 20px;
            background-color: transparent;
            border: 2px solid black;
            border-radius: 50%; /* Make the radio button round */
        }

        .checkmark__ingredients {
            position: absolute;
            top: 6px;
            left: 0;
            height: 20px;
            width: 20px;
            background-color: transparent;
            border: 2px solid black;
            border-radius: 50%; /* Make the radio button round */
        }

        .checkmark:after,
        .checkmark__ingredients:after {
            content: "";
            position: absolute;
            display: none;
            left: 5px; /* Adjust position of the dot for radio buttons */
            top: 5px; /* Adjust position of the dot for radio buttons */
            height: 10px;
            width: 10px;
            border-radius: 50%;
            background-color: #000; /* Change color of the dot for radio buttons */
        }

        /* Show the dot when the radio button is checked */
        .input-checkboxes input:checked + .checkmark:after,
        .input-checkboxes input:checked + .checkmark__ingredients:after {
            display: block;
        }

        label.ingredients-label {
            margin-left: 4px;
        }

        input {
            border-radius: 0;
            -webkit-border-radius:0px;
        }

        input[type="search"] {
            border-radius: 0;
            -webkit-appearance: none;
            -webkit-border-radius:0px;
        }

        .input-label {
            font-size: 16px;
            color: #000;
            margin-bottom: 5px;
        }

        .input-field {
            width: 230px;
            height: 40px;
            padding: 5px;
            border: none;
            border-bottom: 1px solid #000;
            font-size: 14px;
            --webkit-appearance: none;
            background-color: #fff;
        }

        .input-field:focus {
            outline: none;
            border-color: #000;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); 
            font-size: 18px;
        }

        .input-field::-webkit-search-cancel-button {
            display: none; /* Hide the default clear button on Chrome */
        }
        
        .input-field,
        .input-field::-webkit-search-cancel-button {
            background-color: white; /* Change background color to white */
        }

        .clear-button {
            visibility: hidden;
            position: absolute;
            top: 20px;
            left: 265px;
            transform: translateY(-50%);
            border: none;
            background: none;
            cursor: pointer;
            padding: 0;
            margin: 0;
            font-size: 16px;
            line-height: 1;
            color: #000;
        }

        .helper-text {
            display: inline-block;
            font-size: 10px;
            margin: 3px 0 0 40px;
        }

        .input-checkboxes__ingredients {
            flex-direction: column;
            align-items: flex-start;
        }

        .input-checkboxes__titles,
        .input-checkboxes__instructions {
            margin-bottom: 8px;
        }

        a {
            word-wrap: break-word;
        }


        /* Extra small devices (phones, 600px and down) */
        @media only screen and (max-width: 600px) {
            .input-checkboxes {
                flex-direction: column;
            }
            .app-recipe-card {
                font-size: var(--font-size-sm);
                margin-top: var(--spacing-md);
            }

            .app-recipe-card--content {
                display: none;
                margin-left: var(--spacing-sm);
            }

            .ingredients, .instructions {
                h3 {
                    margin-left: 0;
                }
                h4, h5 {
                    margin-left: var(--spacing-xs);
                }
                ol, ul {
                    margin-left: var(--spacing-sm);
                }
            }

            .input-checkboxes__titles,
            .input-checkboxes__instructions {
                margin-top: 7px;
            }
        }
        .input-checkboxes {
            font-family: Arial, sans-serif;
            display: flex;
        }
        
        /* Extra large devices (large laptops and desktops, 1200px and up) */
        @media only screen and (min-width: 1200px) {
                    
            .input-checkboxes__titles,
            .input-checkboxes__ingredients,
            .input-checkboxes__instructions,
            h3 {
                display: flex;
                align-items: flex-start;
                margin-left: 96px;
            }

            .input-checkboxes__titles,
            .input-checkboxes__instructions {
                align-items: center;
            }

            .input-search-container {
                margin-top: 60px;
                margin-left: 96px;
                margin-bottom: -40px;
                font-family: Arial, sans-serif;
            }
            .app-recipe-card {
                margin: 96px;
                font-size: var(--font-size-xl);
            }

        } 
  `;

  render() {
      return html`
        <h3>Search titles by:</h3>
        <div class="input-checkboxes">
            <div class="input-checkboxes__titles">
              <input id="search-categories-titles" name="search-category" type="radio" class="input-check" value="title" @change="${this.handleCheckboxChange}" checked>
              <label for="search-categories-titles" class="checkmark"></label>
              <label for="search-categories-titles">titles</label>
            </div>
            <div class="input-checkboxes__ingredients">
              <div>
                  <input id="search-categories-ingredients" name="search-category" type="radio" class="input-check" value="ingredients" @change="${this.handleCheckboxChange}">
                  <label for="search-categories-ingredients" class="checkmark checkmark__ingredients"></label>
                  <label for="search-categories-ingredients" class="ingredients-label">ingredients</label>
              </div>
              <p class="helper-text">Ex. butter, salt, beef broth...</p> 
            </div>
            <div class="input-checkboxes__instructions">
              <input id="search-categories-instructions" name="search-category" type="radio" class="input-check" value="instructions" @change="${this.handleCheckboxChange}">
              <label for="search-categories-instructions" class="checkmark"></label>
              <label for="search-categories-instructions">instructions</label>
            </div>
        </div>
        <div class="input-search-container">
            <label for="search">Search:</label>
            <input id="search" type="search" class="input-field" @input=${this.handleInput} placeholder="Bacon...">
            <button @click=${this.clearSearch} class="clear-button">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16"><path fill="#888" d="M19.29 5.29L12 12.59l-7.29-7.3a1 1 0 0 0-1.42 1.42L10.59 14 3.29 21.29a1 1 0 0 0 1.42 1.42L12 15.41l7.29 7.3a1 1 0 0 0 1.42-1.42L13.41 14l6.3-6.29a1 1 0 0 0 0-1.42z"/></svg>
            </button>
        </div>
      ${this.filteredItems.map((recipe, i) => 
            html`
                <div class="app-recipe-card">
                    <div class="app-recipe-card--title">
                        <app-toggle-button .isNavbar=${false} @checked=${this.toggleContent}>
                            <h1>${recipe.title}</h1>
                        </app-toggle-button>
                    </div>
                    <div id=${recipe.title} class="app-recipe-card--content">
                        <div class="ingredients">
                            <h3 class="ingredients-title">Ingredients:</h3>
                            ${unsafeHTML(DOMPurify.sanitize(recipe.ingredients, {FORBID_TAGS: ['script', 'a'] }))}
                        </div>
                        <div class="instructions">
                            <h3 class="instructions-title">Cooking Steps:</h3>
                            ${unsafeHTML(DOMPurify.sanitize(recipe.instructions, {FORBID_TAGS: ['script', 'a'] }))}
                        </div>
                        <div>
                            <h4>Original Link:</h4><a href="${this.originalLink}" target="_blank">${this.originalLink}</a>
                        </div>
                    </div>
                </div>
            `
        )}
      `;
    }

    clearSearch() {
        const searchInput = this.shadowRoot.getElementById('search');
        if (searchInput) {
            searchInput.value = ''; // Clear the value in the search input field
            this.query = ''; // Update the query property to reflect the cleared value
            this.toggleClearButton(); // Toggle visibility of clear button

            // rotate toggle button
            const buttonToggle = this.shadowRoot.querySelectorAll('app-toggle-button')
            buttonToggle.forEach(btn => {
                btn.shadowRoot.querySelector('.button-toggle').style.transform = null;
            })
            // Hide all recipe cards
            const recipeCards = this.shadowRoot.querySelectorAll('.app-recipe-card--content');
            recipeCards.forEach(card => {
                card.style.display = 'none';
            });
        }
    }

    toggleClearButton() {
        const clearButton = this.shadowRoot.querySelector('.clear-button');
        if (clearButton) {
            clearButton.style.visibility = this.query ? 'visible' : 'hidden';
        }
    }

    handleCheckboxChange(event) {
        const checkbox = event.target;
        const value = checkbox.value;
    
        // Clear the checkedItems array
        this.checkedItems = [];
    
        // Add the newly selected item
        if (checkbox.checked) {
            this.checkedItems = [value];
        }
    }

    toggleContent(e) {
        this.isMenuOpened = e;
        const navPanel = e.target.parentElement.nextSibling.nextSibling;
        const isClicked = e.detail.value;

        if (isClicked) {
            navPanel.style.display = 'flex'
        } else {
            navPanel.style.display = 'none'
        }
    }

    updated(changedProperties) {
        if (changedProperties.has('query')) {
            this.updateList();
        }
        if (changedProperties.has('checkedItems')) {
            this.updateList();
        }
    }

    updateList() {
        // Update the list here
        this.requestUpdate(); // Trigger a re-render
    }

    firstUpdated() {
        this.updateList(); // Call updateList after the initial DOM update
    }

    get filteredItems() {
        const ingredients = this.checkedItems?.includes('ingredients');
        const instructions = this.checkedItems?.includes('instructions');
    
        if (this.checkedItems.length === 0 || this.checkedItems.includes('title')) {
            // If no checkboxes are checked or "titles" checkbox is checked, return all items
            return this.items.filter(item =>
                item.title.toLowerCase().includes(this.query.toLowerCase())
            );
        } else {
            // Filter based on checked checkboxes
            return this.items.filter(item => {
                let matches = false;
    
                if (ingredients && instructions) {
                    // Both checkboxes are checked
                    matches = this.query.split(',').every(term =>
                        item.ingredients.toLowerCase().includes(term.trim().toLowerCase())
                    );
                } else if (ingredients) {
                    // Only ingredients checkbox is checked
                    matches = this.query.split(',').every(term =>
                        item.ingredients.toLowerCase().includes(term.trim().toLowerCase())
                    );
                } else if (instructions) {
                    // Only instructions checkbox is checked
                    matches = item.instructions.toLowerCase().includes(this.query.toLowerCase());
                }
                
                return matches;
            });
        }
    }
    

    handleInput(event) {
        this.query = event.target.value;
        this.toggleClearButton();
    }
}

customElements.define('app-search', Search);
