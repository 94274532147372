export const desserts = [
    {
        title: 'Pie Crust Cookies',
        ingredients: `
            <ul>
                <li> 2 unbaked pie crusts            </li>
                <li> 1/2 cup granulated sugar        </li>
                <li> 3 tablespoons brown sugar       </li>
                <li> 2 1/2 teaspoons ground cinnamon </li>
            </ul>
        `,
        instructions: `
                <ul>
                   <li> Preheat oven to 350 degrees F and line a baking sheet or cookie sheet with parchment paper or a silpat mat.                                                                                                              </li>
                   <li> On a lightly floured surface, roll out each pie crust with a rolling pin to about a 12 x 18 inch rectangle.                                                                                                              </li>
                   <li> In a small bowl, combine the granulated sugar, brown sugar, and cinnamon.                                                                                                                                                </li>
                   <li> Sprinkle half of the cinnamon sugar mixture to the top of one rolled out pie crust. Cover the edges and gently press the mixture down with your hands. Roll pie crust tightly.                                           </li>
                   <li> Slice cookie log 1/2 inch thick size cookies and place on prepared baking sheet. Repeat with the other pie crust. These cookies won't spread so you can fit these on one sheet most likely.                              </li>
                   <li> Bake for 14-16 minutes or until the tops of the cookies are a light golden brown. Let cool for a few minutes, then transfer to a cooling rack. Top with melted white chocolate, icing, or powdered sugar (all optional). </li>
                </ul>
        `,
        originalLink: 'https://kathrynskitchenblog.com/pie-crust-cookies-recipe/#recipe'
    },
    {
        title: 'Cinnamon-Swirl Chocolate Chip Bread',
        ingredients: `
                <h4>Cinnamon Swirl</h4>
                <ul>
                    <li> 1 Tablespoon ground cinnamon </li>
                    <li> 1/3 cup (65g) granulated sugar </li>                    
                </ul>
                <h4>Bread</h4>
                <ul>
                    <li> 2 cups (250g) all-purpose flour (spooned & leveled)             </li>
                    <li> 1 teaspoon baking soda                                          </li>
                    <li> 1/2 teaspoon salt                                               </li>
                    <li> 2/3 cup (120g) mini semi-sweet chocolate chips                  </li>
                    <li> 1 large egg, at room temperature                                </li>
                    <li> 1/2 cup (100g) packed light or dark brown sugar                 </li>
                    <li> 1/4 cup (50g) granulated sugar                                  </li>
                    <li> 1 cup (240ml) buttermilk, at room temperature                   </li>
                    <li> 1/3 cup (80ml) vegetable oil (or canola; or melted coconut oil) </li>
                    <li> 1 teaspoon pure vanilla extract </li>
                </ul>
        `,
        instructions: `
            <ul>
                <li> Preheat oven to 350°F (177°C). Spray a 9×5-inch loaf pan with nonstick spray. I prefer a dark metal loaf pan, which seems to help brown quick breads more evenly.                                                                                                                                                                                                                                                                                      </li>
                <li> Make the cinnamon-swirl first by combining the cinnamon and sugar together in a small bowl. Set aside.                                                                                                                                                                                                                                                                                                                                                 </li>
                <li> Make the bread: Whisk the flour, baking soda, and salt together in a large bowl. Stir in the chocolate chips. Set aside. In a medium bowl, whisk the egg, brown sugar, and granulated sugar together until combined. Whisk in the buttermilk, oil, and vanilla. Slowly pour the wet ingredients into the dry ingredients and gently whisk until there are no more lumps. Avoid over-mixing.                                                            </li>
                <li> You’ll have about 3-3.5 cups of batter. Spread half of the batter into prepared loaf pan. Top evenly with 3/4 of your cinnamon-sugar mixture. Gently spread the remaining batter on top as best you can and sprinkle the rest of the cinnamon-sugar. Sprinkle with a couple more mini chocolate chips if desired. Using a knife, gently swirl the batter with 1 simple swirl (I usually swirl a “S” shape) from the top to the bottom of the loaf pan. </li>
                <li> Bake the bread for 50-65 minutes, tenting loosely with aluminum foil halfway through bake time. Quick breads are thick and dense, so don’t be alarmed if your loaf is taking longer. To test for doneness, poke the center of the bread with a toothpick. If it comes out clean, the bread is done. Allow bread to cool completely in the pan on a wire rack before cutting. </li>
                <li> Cover and store leftover bread at room temperature for 3 days or in the refrigerator for up to 1 week. </li>
            </ul>
        `,
        originalLink: 'https://sallysbakingaddiction.com/cinnamon-swirl-chocolate-chip-bread/#tasty-recipes-76136'
    },
    {
        title: 'Maple Snickerdoodles',
        ingredients: `
                <h4>Snickerdoodle</h4>
                <ul>
                    <li> 3/4 cup butter, softened     </li>
                    <li> 3/4 cup granulated sugar     </li>
                    <li> 1/4 cup brown sugar          </li>
                    <li> 1/4 cup pure maple syrup     </li>
                    <li> 2 tsp vanilla extract        </li>
                    <li> 1 egg                        </li>
                    <li> 2 tsp baking soda            </li>
                    <li> 2 1/4 cups all purpose flour </li>
                    <li> 1 1/2 tsp ground cinnamon </li>
                    <li> 1 tsp cream of tartar </li>
                    <li> 1/2 tsp salt </li>
                </ul>
                <h4>Snickerdoodle</h4>
                <ul>
                    <li> 1/4 cup sugar </li>
                    <li> 1 Tbsp ground cinnamon </li>
                </ul>
        `,
        instructions: `
            <ul>
                <li> Cream butter, granulated sugar, and brown sugar together, until light and fluffy.                                                                                                      </li>
                <li> Add in maple syrup, vanilla extract, and egg; beat until well combined.                                                                                                                </li>
                <li> Sift dry ingredients together in a separate bowl, and slowly incorporate into the wet ingredients.                                                                                     </li>
                <li> Chill dough for at least 1 hour.                                                                                                                                                       </li>
                <li> Preheat oven to 375 degrees and line a baking sheet with parchment paper.                                                                                                              </li>
                <li> Mix cinnamon and sugar together in a small bowl.                                                                                                                                       </li>
                <li> Roll dough into about 1 1/2" balls, and the roll in cinnamon sugar. Place 2" apart on the baking sheet, and bake for 8-10 minutes, or until tops have cracked and the cookies are set. </li>
            </ul>
        `,
        originalLink: 'https://www.alattefood.com/maple-snickerdoodles/#wprm-recipe-container-9594'
    },
    {
        title: 'Soft and Chewy Sugar Cookies',
        ingredients: `
            <ul>
               <li> 2½ cups (318 grams) all-purpose flour                                   </li>
               <li> 2 teaspoons baking powder                                               </li>
               <li> 3/4 teaspoon fine sea salt                                              </li>
               <li> 2 sticks (226 grams) unsalted butter, at cool room temperature          </li>
               <li> 1¼ cups (250 grams) granulated sugar, plus ¼ cup (50 grams) for rolling </li>
               <li> 1 large egg plus 1 egg yolk </li>
               <li> 1 teaspoon vanilla extract </li>
            </ul>
        `,
        instructions: `
            <ul>
               <li> Preheat the oven to 350°F. Line baking sheets with parchment paper.                                                                                                                                                                                                                                                                                            </li>
               <li> In a medium bowl, whisk together the flour, baking powder, and salt to combine.                                                                                                                                                                                                                                                                                </li>
               <li> In a large bowl, use an electric mixer fitted with a paddle attachment on medium-high speed to beat the butter and 1¼ cups sugar until light and fluffy, 2 to 3 minutes. Scrape down the sides and bottom of the mixing bowl. Add the egg, egg yolk, and vanilla, and beat until combined, scraping the bowl down as needed. Slowly beat in the flour mixture. </li>
               <li> Place the remaining 1/4 cup sugar in a shallow dish. Using a large (3-tablespoon) spring-loaded scoop, divide the dough into balls, then roll in sugar to coat evenly. Place the dough balls on the prepared baking sheets, spacing 2 inches apart, and flatten slightly with the bottom of a measuring cup. </li>
               <li> Bake for 10 to 12 minutes, or until the cookies set and begin to brown. Cool for 5 minutes before removing to a wire rack to cool completely. Cookies can be stored in an airtight container at room temperature for up to 3 days. </li>
            </ul>
        `,
        originalLink: 'https://handletheheat.com/soft-chewy-sugar-cookies/#wprm-recipe-container-44195'
    },
    {
        title: 'No Bake Energy Bites',
        ingredients: `
            <ul>
                <li> 1 cup old-fashioned oats </li>
                <li> 2/3 cup toasted shredded coconut (sweetened or unsweetened) </li>
                <li> 1/2 cup creamy peanut butter                                </li>
                <li> 1/2 cup ground flaxseed                                     </li>
                <li> 1/2 cup semisweet chocolate chips (or vegan chocolate chips)</li>
                <li> 1/3 cup honey                                               </li>
                <li> 1 tablespoon chia seeds (optional)                          </li>
                <li> 1 teaspoon vanilla extract                                  </li>
            </ul>
        `,
        instructions: `
            <ul>
                <li> Stir everything together.  Stir all ingredients together in a large mixing bowl until thoroughly combined. </li>
                <li> Chill. Cover the mixing bowl and chill in the refrigerator for 1-2 hours, or until the mixture is chilled.  (This will help the mixture stick together more easily.) </li>
                <li> Roll into balls.  Roll into mixture into 1-inch balls.                                                                                                               </li>
                <li> Serve.  Then enjoy immediately!  Or refrigerate in a sealed container for up to 1 week, or freeze for up to 3 months.                                                </li>
            </ul>
        `,
        originalLink: 'https://www.gimmesomeoven.com/no-bake-energy-bites/#tasty-recipes-66977'
    },
    {
        title: 'Claire Saffitz Chocolate Chip Cookies',
        ingredients: `
            <ul>
                <li> 226 g (2 sticks) unsalted butter                                    </li>
                <li> 28 g (2 tablespoons) heavy cream, half-and-half, or whole milk      </li>
                <li> 260 g (2 cups) all-purpose flour                                    </li>
                <li> 6 g (2 teaspoons) Diamond Crystal kosher salt                       </li>
                <li> 6 g (1 teaspoon) baking soda                                        </li>
                <li> 150 g (¾ cup packed) dark brown sugar                               </li>
                <li> 150 g (¾ cup) granulated sugar                                      </li>
                <li> 100 g (2 large) eggs, cold from the refrigerator                    </li>
                <li> 15 g (1 tablespoon) vanilla extract                                 </li>
                <li> 142 g (5 ounces) bittersweet chocolate disks, half coarsely chopped </li>
                <li> 142 g (5 ounces) milk chocolate disks, half coarsely chopped </li>
            </ul>
        `,
        instructions: `
            <ul>
                <li> Measure out 113 g (1 stick) of the butter and set aside in a large bowl. In a small saucepan, cook the remaining 113 g (1 stick) butter over medium-low heat, stirring frequently, until the butter comes to a boil. Continue to cook, scraping the bottom and sides of the pan constantly with a heatproof spatula, until the butter sputters, foams, and eventually you see browned bits floating about, 5 to 7 minutes. </li>
                <li> Add the browned butter to the bowl with the other butter, making sure you scrape in all the browned bits, then add the heavy cream (no need to stir). Set aside to cool.                                                                                                                                                                                                                                                   </li>
                <li> In a medium bowl, whisk together the flour, salt, and baking soda to combine. Set it aside.                                                                                                                                                                                                                                                                                                                                </li>
                <li> To the bowl with the browned butter mixture (it can be slightly warm, just make sure it's not hot), add the brown and granulated sugars and whisk vigorously until the mixture is very smooth and thick, about 45 seconds. Add the eggs and vanilla and whisk until the mixture is satiny, about 45 seconds.                                                                                                               </li>
                <li> Add the flour mixture and whisk until the batter is smooth and well combined. It will look a little loose-this is normal. Switch to a flexible spatula to scrape down the bowl, folding to make sure everything is well incorporated.                                                                                                                                                                                      </li>
                <li> Add both the chocolates and mix to combine. Set the batter aside for 5 minutes to firm up slightly.                                                                                                                                                                                                                                                                                                                        </li>
                <li> Using a 2-ounce scoop or ¼ cup measure, scoop level portions of dough and place on a parchment-lined baking sheet as close together as possible. Cover the sheet tightly with plastic wrap and refrigerate for at least 12 hours and up to 48 (if you're pressed for time, a couple of hours in the refrigerator will do-just note the baked cookies won't be as chewy or wrinkly-looking).                                </li>
                <li> When you're ready to bake, arrange two oven racks in the upper and lower thirds of the oven and preheat to 180 ºC (350 °F). Line two rimmed baking sheets with parchment paper.                                                                                                                                                                                                                                            </li>
                <li> Place 6 pieces of chilled cookie dough on each of the prepared baking sheets, spacing them so they're at least 7 cm (3 inches) apart.                                                                                                                                                                                                                                                                                      </li>
                <li> Bake the cookies on the upper and lower racks until they are dark golden brown around the edges, 18 to 22 minutes, switching racks and rotating the sheets front to back after 12 minutes. Allow the cookies to rest for 5 minutes on the baking sheets, then use a metal spatula to transfer the cookies to a wire rack to cool.                                                                                          </li>
                <li> Repeat the process with the remaining dough. </li>
            </ul>
        `,
        originalLink: 'https://www.thecookingworld.com/recipes/claire-saffitz-chocolate-chip-cookies'
    },
    {
        title: 'Instant Pot Funfetti Cheesecake',
        ingredients: `
                <h4>Crust</h4>
                <ul>
                    <li> Nonstick cooking spray, for preparing the pan </li>
                    <li> 1 1/2 cups Golden Oreo crumbs (about 18 cookies, crushed) </li> 
                    <li> 3 tablespoons butter, melted </li>
                </ul>
                <h4>Filling</h4>
                <ul>
                    <li> 2 packages (8 ounces each) cream cheese, at room temperature </li>
                    <li> 1/2 cup sugar                                                </li>
                    <li> 1/4 cup plain 2% Greek yogurt                                </li>
                    <li> 1/2 cup boxed yellow cake mix                                </li>
                    <li> 2 teaspoons vanilla extract                                  </li>
                    <li> 2 large eggs, at room temperature                            </li>
                    <li> 1/4 cup sprinkles, plus more for garnishing                  </li>
                </ul>
                <h4>Whipped Topping</h4>
                <ul>
                    <li> 2 cups heavy cream          </li>
                    <li> 1/2 cup powdered sugar      </li>
                    <li> 2 teaspoons vanilla extract </li>
                </ul>
        `,
        instructions: `
            <ul>
                <li> <strong>To make the crust:</strong> Coat a 7-inch (18 cm) springform pan with nonstick cooking spray. </li>
                <li> In a small bowl, stir together the cookie crumbs and melted butter. Spread the crust evenly in the bottom and no more than 1 inch up the side of the prepared pan. Freeze for 10 minutes.</li>
                <li> <strong>To make the filling:</strong> in a large bowl, with a handheld electric mixer, mix the cream cheese and sugar on medium speed until smooth.                                                       </li>
                <li> Blend in the yogurt, cake mix, and vanilla.                                                                                                                                              </li>
                <li> One at a time, add the eggs and mix just until blended. Do not overmix.                                                                                                                  </li>
                <li> Working quickly so the color on the sprinkles doesn’t run, stir in the sprinkles and pour the batter over the crust.                                                                     </li>
                <li> Pour 1 cup of water into the pressure cooker cooking pot and place a trivet in the bottom. Carefully center the filled pan on a sling and lower the pan onto the trivet.                 </li>
                <li> Lock the lid in place and turn the pressure release valve to the Sealed position.                                                                                                        </li>
                <li> Select High Pressure and 35 minutes cook time.                                                                                                                                           </li>
                <li> When the cook time ends, turn off the pressure cooker. Let the pressure release naturally for 10 minutes, and finish with a quick pressure release.                                      </li>
                <li> When the valve drops, carefully remove the lid. The cheesecake should be slightly jiggly in the center.  If it's underdone, cook for another 5 minutes in the pressure cooker.           </li>
                <li> With the sling, transfer the pan to a wire rack to cool. Soak up any water that may have collected on top of the cheesecake with the corner of a paper towel.                            </li>
                <li> When the cheesecake is cool, refrigerate, covered with plastic wrap, for at least 4 hours, or overnight, until ready to serve.                                                           </li>
                <li> Just prior to serving, spread or pipe the whipped cream on top of the cheesecake. Garnish with a few more sprinkles.                                                                     </li>
                <li> <strong>To make the whipped cream:</strong> In a medium bowl, using a handheld electric mixer, beat the cream on medium-high speed until it starts to thicken. Add the powdered sugar and vanilla and continue to beat until stiff peaks form.</li>
            </ul>
        `,
        originalLink: 'https://instafreshmeals.com/instant-pot-funfetti-cheesecake/#tasty-recipes-9350'
    },
    {
        title: 'Pumpkin Crisp',
        ingredients: `
                <h4>Filling</h4>
                <ul>
                    <li> One 15-ounce can pumpkin puree </li>
                    <li> 2/3 cup sugar                  </li>
                    <li> One 5-ounce can evaporated milk</li>
                    <li> 3 eggs                         </li>
                    <li> 1 teaspoon vanilla extract     </li>
                    <li> 2 teaspoon pumpkin pie spice   </li>
                    <li> 1/4 teaspoon salt              </li>
                </ul>
                <h4>Topping</h4>
                <ul>
                    <li> 1 cup Gingersnap cookie "flour"* </li>
                    <li> 1 cup quick oats                 </li>
                    <li> 1/3 cup brown sugar              </li>
                    <li> 1/4 teaspoon baking soda         </li>
                    <li> 1/4 teaspoon baking powder       </li>
                    <li> 1/2 cup butter softened          </li>
                </ul>
        `,
        instructions: `
            <ul>
                <li> Preheat oven to 350°F.                                                                                                                                        </li> 
                <li> Grease a deep square pan or a 2-3 qt casserole dish.                                                                                                          </li> 
                <li> In a large bowl, whisk together all of the filling ingredients; pour into the prepared dish.                                                                  </li> 
                <li> In a medium bowl, combine the gingersnap cookie flour*, oats, brown sugar, soda and powder. Then, use a fork to incorporate the butter into the dry mixture.  </li> 
                <li> Evenly sprinkle the topping over the pumpkin filling.                                                                                                         </li> 
                <li> Bake for 35-45 minutes, or until the center of the pumpkin pie filling reaches 175°F. The center will still have a little wiggle, but it won't be super runny.</li> 
                <li> Serve with ice cream or whipped topping, if desired. </li>
            </ul>
        `,
        originalLink: 'https://dancearoundthekitchen.com/pumpkin-crisp/#wprm-recipe-container-1142'
    },
    {
        title: 'Easy Cherry Cobbler',
        ingredients: `
            <ul>
                <li> 2 (21 ounce) cans cherry pie filling         </li>
                <li> 1 ½ teaspoons almond extract (optional)      </li>
                <li> ½ cup (1 stick) salted butter, melted        </li>
                <li> 1 (9 ounce) box Jiffy Golden Yellow Cake Mix </li>
                <li> 1 teaspoon ground cinnamon (optional) </li>
            </ul>
        `,
        instructions: `
            <ul>
                <li> Preheat the oven to 350° F. Spray a 10-inch cast iron skillet with nonstick cooking spray. </li> 
                <li> Pour the cans of pie filling into the bottom of the skillet. Stir in the almond extract, if using. </li>
                <li> In a medium bowl, stir together the cake mix, melted butter, and cinnamon. It will be thick.       </li>
                <li> Drop the batter by dollops on top of the filling.                                                  </li>
                <li> Bake for 50 to 60 minutes or until the batter is set. Serve warm.                                  </li>
            </ul>
        `,
        originalLink: 'https://www.thecountrycook.net/skillet-cherry-cobbler/#recipe'
    },
    {
        title: 'Cupcake Hack',
        ingredients: `
            <ul>
                <li> 1 Box Funfetti cake mix </li>
                <li> 2/3 Cups Melted Butter  </li>
                <li> 1/4 Cup Sprinkles       </li>
                <li> 4 Large Eggs            </li>
                <li> 1 Cup Milk              </li>
            </ul>
        `,
        instructions: `
            <ul>
                <li> Preheat oven to 350°F and prep your 'ingredients'                          </li>
                <li> Line your cupcake tins                                                     </li>
                <li> Pour in cake mix                                                           </li>
                <li> Add eggs, melted butter, and milk.                                         </li>
                <li> Stir or mix on low for 2 minutes.                                          </li>
                <li> Scoop batter so the cups are 3/4 full.                                     </li>
                <li> Bake for 18 minutes, or until a toothpick is inserted and comes out clean. </li>
                <li> When the cupcakes are completely cool, frost, add sprinkles. </li>
            </ul>
        `,
        originalLink: 'https://www.yahoo.com/lifestyle/hack-makes-boxed-cake-mix-185335176.html'
    },
    {
        title: 'Key Lime Pie',
        ingredients: `
            <h4>FOR THE CRUST</h4>
            <ul>
                <li> 1½ cups finely crushed graham cracker crumbs, from about 12 whole graham crackers</li>
                <li> ⅓ cup packed light brown sugar                                                   </li>
                <li> 4 tablespoons unsalted butter, melted                                            </li>
            </ul>
            <h4>FOR THE FILLING</h4>
            <ul>
                <li> Two 14-oz cans sweetened condensed milk    </li>
                <li> 1 cup plain Greek yogurt (2% or whole milk)</li>
                <li> 1 tablespoon grated lime zest              </li>
                <li> ¾ cup fresh lime juice                     </li>
            </ul>
            <h4>FOR THE TOPPING</h4>
            <ul>
                <li> 1 cup cold heavy cream            </li>
                <li> 2 tablespoons confectioners' sugar</li>
                <li> 1 teaspoon grated lime zest       </li>
                <li> 8 to 10 thin lime slices          </li>
            </ul>
        `,
        instructions: `
            <h4>FOR THE CRUST</h4>
            <ul>
                <li> Preheat oven to 375 °F and set an oven rack in the middle position. </li>
                <li> In a medium bowl, combine the graham cracker crumbs, brown sugar, and melted butter; stir with a fork first, and then your hands until the mixture is well combined. Using your fingers and the bottom of a glass or dry measuring cup, press the crumbs firmly into the bottom and up the sides of a 9 x 1.5-inch (deep-dish) pie pan. The crust should be about ¼-inch thick. (Tip: do the sides first.) </li>
                <li> Bake for 10 minutes, until just slightly browned. Let the crust cool on a wire rack. </li>
            </ul>
            <h4>FOR THE FILLING</h4>
            <ul>
                <li>Lower the oven temperature to 350°F.</li>
                <li>In a large bowl, whisk together the sweetened condensed milk, yogurt, lime zest, and lime juice. Pour the thick mixture into the warm graham cracker crust. Bake for 15 minutes, until the filling is almost set; it should wobble a bit. Let cool at room temperature for 30 minutes, then place in the refrigerator to chill thoroughly, about 3 hours.</li>
            </ul>
            <h4>FOR THE TOPPING</h4>
            <ul>
                <li>In the bowl of an electric mixer, beat the heavy cream until soft peaks form. Add the confectioners’ sugar and beat until medium peaks form. Top the pie with the whipped cream. Decorate with the lime zest and lime slices. Store the pie in the refrigerator until ready to serve. Slice the pie into wedges, wiping your knife clean between slices, and serve cold.</li>
            </ul>
        `,
        originalLink: 'https://www.onceuponachef.com/recipes/key-lime-pie.html#tabrecipe'
    },
    {
        title: 'Jello Cake',
        ingredients: `
            <ul>
                <li> 1 (15.25-ounce) box white cake mix plus any ingredients listed on box </li>
                <li> 1 (3-ounce) box strawberry Jell-O (or raspberry or cherry)            </li>
                <li> 1 cup boiling water                                                   </li>
                <li> 1 cup cold water                                                      </li>
                <li> 1 (8-ounce) tub whipped topping (or 2 cups whipped cream)             </li>
            </ul>
        `,
        instructions: `
            <ul>
                <li> Prepare cake as directed on the box and bake in a 9-x-13-inch baking dish. Let cool for 15 minutes. </li>
                <li> Poke cake with a fork at ½–1-inch intervals.                                                        </li>
                <li> Use hot and cold water to make Jell-O as directed on the package.                                   </li>
                <li> Spoon Jell-O liquid over the cake gradually until it is all absorbed.                               </li>
                <li> Chill in the refrigerator for 3–4 hours. Top with whipped topping right before serving.             </li>
            </ul>
        `,
        originalLink: 'https://lilluna.com/jello-poke-cake/#wprm-recipe-container-95477'
    },
    {
        title: 'Butterscotch',
        ingredients: `
            <ul>
                <li> 4 oz (113g) butter           </li>
                <li> 1/2 c (107g) dark brown sugar</li>
                <li> 1 tsp (6g) fine salt         </li>
            </ul>
        `,
        instructions: `
            <ul>
                <li> Add butter, dark brown sugar, and salt to a pot over medium heat.    </li>
                <li> Whisk continuously until the mixture thickens and reaches 300F (150C)</li>
                <li> Spread onto a lined sheet pan and let cool and harden.               </li>
                <li> Once cool, break into small pieces.                                  </li>
            </ul>
        `,
        originalLink: 'https://www.benjaminthebaker.com/recipes/v/butterscotch-chocolate-chip-cookies'
    }
]