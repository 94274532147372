import { LitElement, html, css } from "lit-element";
import { router } from "lit-element-router";
import { sideDishes } from "../data/side-dishes";
import { dinnerRecipes } from "../data/dinner";
import { desserts } from "../data/desserts";
import { seasonings } from "../data/seasonings";
import { sauces } from "../data/sauces";
import { brunch } from "../data/brunch";

import "./app-main.js";
import "./app-navbar.js";
import "./app-link.js";
import "./app-recipe-card.js";
import "./app-search.js";

export class AppLofi extends router(LitElement) {
  static get properties() {
    return {
      route: { type: String },
      params: { type: Object },
      query: { type: Object },
      data: { type: Object },
      recipe: { type: String }
    };
  }

  static styles = css`
    :host {
      width: 100%;
    }

    .search-link {
      align-items: center;
    }
  `

  static get routes() {
    return [
      {
        name: 'search',
        pattern: ''
      },
      {
        name: "dinner",
        pattern: "dinner",
      },
      {
        name: "brunch",
        pattern: "brunch"
      },
      {
        name: "dessert",
        pattern: "dessert"
      },
      {
        name: "sauces",
        pattern: "sauces"
      },
      {
        name: "seasonings",
        pattern: "seasonings"
      },
      {
        name: "side-dishes",
        pattern: "side-dishes"
      },
      {
        name: "not-found",
        pattern: "*"
      }
    ];
  }

  constructor() {
    super(router);
    this.route = "";
    this.dinnerRecipes = this.sortList(dinnerRecipes);
    this.sideDishes = this.sortList(sideDishes);
    this.brunch = this.sortList(brunch);
    this.desserts = this.sortList(desserts);
    this.sauces = this.sortList(sauces);
    this.seasonings = this.sortList(seasonings);
    this.allRecipes = this.sortList([
      ...dinnerRecipes,
      ...sideDishes,
      ...brunch,
      ...desserts,
      ...sauces,
      ...seasonings
    ]);
  }
  
  sortList(recipes) {
    return recipes.sort((a, b) => a.title.localeCompare(b.title));
  }

  router(route) {
    this.route = route;
  }

  render() {
      return html`
      <app-navbar .route="${this.route}">
        <div slot="categories-1">
          <app-link href="/dinner" .isActive="${this.route === 'dinner'}">Dinners</app-link>
          <app-link href="/side-dishes" .isActive="${this.route === 'side-dishes'}">Side Dishes</app-link>
          <app-link href="/brunch" .isActive="${this.route === 'brunch'}">Brunches</app-link>
        </div>
        <div slot="categories-2">
          <app-link href="/dessert" .isActive="${this.route === 'dessert'}">Desserts</app-link>
          <app-link href="/sauces" .isActive="${this.route === 'sauces'}">Sauces</app-link>
          <app-link href="/seasonings" .isActive="${this.route === 'seasonings'}">Seasonings</app-link>
        </div>
      </app-navbar>
      
      ${this.route === "search" ?
        html`
        <app-main active-route=${this.route}>
          <div class="search-input" route="search">
              <app-search .items=${this.allRecipes}></app-search>
          </div>
        </app-main>
        `
        :
        html`
        <app-main active-route=${this.route}>
          ${this.dinnerRecipes.map(recipe => 
          html`<app-recipe-card 
          route="dinner" 
          .title="${recipe.title}"
          .ingredients="${recipe.ingredients}"
          .instructions="${recipe.instructions}"
          .originalLink="${recipe.originalLink}"
          ></app-recipe-card>`
        )}
        ${this.sideDishes.map(recipe => 
          html`<app-recipe-card 
          route="side-dishes" 
          .title="${recipe.title}"
          .ingredients="${recipe.ingredients}"
          .instructions="${recipe.instructions}"
          .originalLink="${recipe.originalLink}"
          ></app-recipe-card>`
        )}
        ${this.desserts.map(recipe => 
          html`<app-recipe-card 
          route="dessert" 
          .title="${recipe.title}"
          .ingredients="${recipe.ingredients}"
          .instructions="${recipe.instructions}"
          .originalLink="${recipe.originalLink}"
          ></app-recipe-card>`
        )}
        ${this.seasonings.map(recipe => 
          html`<app-recipe-card 
          route="seasonings" 
          .title="${recipe.title}"
          .ingredients="${recipe.ingredients}"
          .instructions="${recipe.instructions}"
          .originalLink="${recipe.originalLink}"
          ></app-recipe-card>`
        )}
        ${this.sauces.map(recipe => 
          html`<app-recipe-card 
          route="sauces" 
          .title="${recipe.title}"
          .ingredients="${recipe.ingredients}"
          .instructions="${recipe.instructions}"
          .originalLink="${recipe.originalLink}"
          ></app-recipe-card>`
        )}
        ${this.brunch.map(recipe => 
          html`<app-recipe-card 
          route="brunch" 
          .title="${recipe.title}"
          .ingredients="${recipe.ingredients}"
          .instructions="${recipe.instructions}"
          .originalLink="${recipe.originalLink}"
          ></app-recipe-card>`
        )}
        <h1 route="not-found">Not Found</h1>
        </app-main>
      `}
  `;
  }
}

customElements.define("app-lofi", AppLofi);